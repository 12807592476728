import React from 'react'
import Routes from './Components/Routes';

function App() {
  return (

<Routes />


  );
}

export default App;
