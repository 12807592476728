import React, { Component } from 'react'
import Nav from "../Nav";
import Footer from "../Footer";
import moment from "moment";
import DatePicker from "react-datepicker";
import '../../Styles/CreateEvent.css'
import { get } from 'lodash';


export class EventDetails extends Component {

    state= {
        errors: {        
            title: '',
            region: '',
            startDetails: '',
            endDetails: '',
            description: '',
            repeatOption: '',
            eventParticipants: '', 
            eventType: ''
        },
        borderColors:{
            title: 'none',
            description: 'none',
            region: 'none',
            startDetails: 'none',
            endDetails: 'none',
            eventPassword: 'none',  
            repeatOption: 'none',
            eventParticipants: 'none',
            eventType: 'none' 
        },
        textAreaHeight: '100px',
        message: '',
        disableStartDetails: true,
        disableEndDetails: true
    }



    checkRepeatOptionSelected = () => {
        if(!this.props.values.repeats.repeats){return}
        const { weekly, fortnightly, monthly } = this.props.values.repeats;
        let { errors, borderColors } = this.state;
        errors.repeatOption = '';
        if (!weekly && !fortnightly && !monthly) {
            errors.repeatOption = 'Please Select An Option';
            borderColors.repeatOption = 'red';
        } 
        this.setState({ errors, borderColors });
    }

    

    changeDescription(e){
        let description = e.target.value
        let borderColors = this.state.borderColors
        borderColors.description = '#00988f' 
        this.setState({borderColors, description})
    }

    checkDescriptionError(){
        let borderColors = this.state.borderColors
        let errors = this.state.errors
        if(this.props.values.description === ''){
            errors.description = 'Please Provide A Description'
            borderColors.description = 'red'
        }
        else{
            errors.description = ''
            borderColors.description = '#00988f'       
        }
        this.setState({ borderColors, errors})
    }

    changeEndDetails(e){
        let endDetails = e
        let errors = this.state.errors
        errors.endDetails = ''
        this.setState({endDetails, errors})
    }

    changePassword(e){
        let eventPassword = e.target.value
        let errors = this.state.errors
        errors.eventPassword = ''      
        this.setState({eventPassword, errors})
    }


    changeTitle(e){
        let title = e.target.value
        let errors = this.state.errors
        errors.title = ''      
        this.setState({title, errors})
        this.checkTitleError(title)
    }


    changeStartDetails(e){
        let startDetails = e
        let errors = this.state.errors
        errors.startDetails = ''
        this.setState({startDetails, errors})
    }

    checkEndDetailsError(values){        
        let borderColors = this.state.borderColors
        let errors = this.state.errors
        if(moment(values.startDetails).isValid() && values.endDetails === ''){
            errors.endDetails = 'Please Provide An End Time'
            borderColors.endDetails = 'red'
        }
        else if(moment(values.startDetails).isValid() && !moment(values.endDetails).isAfter(moment(values.startDetails))){
         
            errors.endDetails ='Your Event Must End After It Starts'
            borderColors.endDetails = 'red'
        }
        else if(!moment(values.endDetails).isAfter(moment())){
            errors.endDetails = 'Your Event Must End In The Future'
            borderColors.endDetails = 'red'
        }
        else{
            errors.endDetails = ''
            borderColors.endDetails = '#00988f'       
        }

        this.setState({ borderColors, errors})
        
    }

    checkPasswordError(values){
        let borderColors = this.state.borderColors
        let errors = this.state.errors
        errors.eventPassword = ''
        borderColors.eventPassword = '#00988f'  
        if(this.props.amendingEvent && (values.eventPassword === undefined || values.eventPassword.length === 0)){this.setState({ borderColors, errors}); return}
        if(values.eventPassword.length < 6){
            errors.eventPassword = 'Your Password Must Be At Least 6 Characters'
            borderColors.eventPassword = 'red'
        }
     
        this.setState({ borderColors, errors})
    }

    checkRegionError(region){   
        
        let borderColors = this.state.borderColors
        let errors = this.state.errors
        
        if(region === ''){
            errors.region = 'Please Select Your Region'
            borderColors.region = 'red'
        }else{
            errors.region = ''
            borderColors.region = '#00988f' 
        }
        this.setState({errors, borderColors})
    }

    checkStartDetailsError(values){    
        let borderColors = this.state.borderColors
        let startDetails = values.startDetails
        let errors = this.state.errors
        if(startDetails === ''){
            errors.startDetails = 'Please Provide A Start Time'
            borderColors.startDetails = 'red'
        }
        else if(!moment(startDetails).isAfter(moment())){
            errors.startDetails = 'Your Event Must Start In The Future'
            borderColors.startDetails = 'red'
        }else{
            errors.startDetails = ''
            borderColors.startDetails = '#00988f'       
        }
        this.setState({borderColors, errors})
    }

    checkTitleError(title){
        let borderColors = this.state.borderColors
        let errors = this.state.errors
        if(title === ''){
            errors.title = 'Please Name Your Event'
            borderColors.title = 'red'
        }
        else{
            errors.title = ''
            borderColors.title = '#00988f'       
        }
        this.setState({ borderColors, errors})
    }

    checkEventParticipantsError() {
        if (this.props.values.eventFilters.gayMen) {return}
        if (this.props.values.eventFilters.lesbians) {return}
        if (this.props.values.eventFilters.trans) {return}
        if (this.props.values.eventFilters.everybody) {return}
        let errors = this.state.errors;
        let borderColors = this.state.borderColors;
        errors.eventParticipants = 'Please Select An Option';
        borderColors.eventParticipants = 'red';
        this.setState({ errors, borderColors });
    }

    checkEventTypeError() {
        if (this.props.values.eventFilters.sport) {return}
        if (this.props.values.eventFilters.social) {return}
        if (this.props.values.eventFilters.supportGroup) {return}
        if (this.props.values.eventFilters.health) {return}
        if (this.props.values.eventFilters.barClub) {return}
        let errors = this.state.errors;
        let borderColors = this.state.borderColors;
        errors.eventType = 'Please Select An Option';
        borderColors.eventType = 'red';
        this.setState({ errors, borderColors });
    }

    continue(event, values){ 
        event.preventDefault()
        this.checkTitleError(values.title)
        this.checkStartDetailsError(values)
        this.checkEndDetailsError(values)
        this.checkPasswordError(values)
        this.checkDescriptionError(values.title)
        this.checkRepeatOptionSelected()
        this.checkEventParticipantsError()
        this.checkEventTypeError()


        let errors = Object.entries(this.state.errors)
        let elementsWithErrors = []

        errors.forEach(e => {
            if(e[1]!==''){
                elementsWithErrors.push(e[0])
            }
        })

        if(elementsWithErrors.length === 0 ){
            this.props.nextStep('eventDetails') 
            return
        }

        const element = document.getElementById(elementsWithErrors[0]);
        const rect = element.getBoundingClientRect();
        const absoluteTop = rect.top + window.pageYOffset;
        window.scrollTo({ top: absoluteTop - 75, behavior: 'smooth' });
            

    }



      handleTextAreaChange(e){
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`; 
        this.handleFieldChange(e, 'description')
      }

      checkForDateErrors(){
        this.checkStartDetailsError(this.props.values)
        if(this.props.values.endDetails === ''){return}
        this.checkEndDetailsError(this.props.values)
      }

      resetError(field){
          let errors = this.state.errors
          let borderColors = this.state.borderColors
          errors[field]=''
          borderColors[field] = 'white' 

        //   borderColors[field] = 'none' 
        //   if(field === 'repeatOption' || field === 'eventParticipants' || field === 'eventType'){
        //     borderColors[field] = 'white'
        //   }
          this.setState({ borderColors, errors})
      }

      handleFieldChange(event, field, subfield){
        this.resetError(field)
        this.props.changeField(event, field, subfield)
      }

      spinnerVisibility(){
          if(this.props.spin){return {'display': 'block'}} 
          return {'display': 'none'}       
    }
    
    disableOrEnableButton(){
        if(this.props.updating){
            return 'create-event-button disable-button'
        }
        return 'create-event-button'
    }

    enableStartDetailsInput = () => {
        //fixes safari bug where both datepickers are opening once one is opened
        let disableStartDetails = false
        let disableEndDetails = true
        this.setState({disableEndDetails, disableStartDetails})
    }

    enableEndDetailsInput = () => {
        //fixes safari bug where both datepickers are opening once one is opened
        let disableStartDetails = true
        let disableEndDetails = false
        this.setState({disableStartDetails, disableEndDetails})
    }




    declareDayOfEvent(startDetails, repeats){
        if(!repeats){return}
        if(startDetails === ''){return}
        if(this.state.errors.startDetails !== ''){return}
        const date = new Date(startDetails);
        const day = moment(date).format('dddd');
        return (<div className='create-event-repeat-frequency'>Event repeats on {day}s</div>)
    }


    declareMonthlyFrequency(){
        if(!this.props.values.repeats.repeats){return}
        if(!this.props.values.repeats.monthly){return}
        if(this.props.values.startDetails === ""){return}
        if(this.state.errors.startDetails !== ''){return}
        const date = new Date(this.props.values.startDetails);
        const dayOfWeek = moment(date).format('dddd');
        const occurrence = Math.ceil(moment(date).date() / 7);
        const suffix = ['th', 'st', 'nd', 'rd'];
        const v = occurrence % 100;
        const ordinalSuffix = suffix[(v-20)%10] || suffix[v] || suffix[0];
        return `Event repeats on the ${occurrence}${ordinalSuffix} ${dayOfWeek} of every month`;
    }
    

    handleAddException = (date) => {
        this.setState(prevState => ({
            repeats: {
                ...prevState.repeats,
                exceptions: [...prevState.repeats.exceptions, date]
            }
        }));
    };

    handleRemoveException = (index) => {
        this.setState(prevState => {
            const exceptions = [...prevState.repeats.exceptions];
            exceptions.splice(index, 1);
            return {
                repeats: {
                    ...prevState.repeats,
                    exceptions
                }
            };
        });
    };



    displayRepeatFrequencyOptions = () => {

        if(!this.props.values.repeats.repeats){return}

        const { weekly, fortnightly, monthly } = this.props.values.repeats;
    
        return (
            <>
                <p className='create-event-warning' id="repeatOption">{this.state.errors.repeatOption}</p>
                <div className="create-event-repeat-radio-container" style={{borderColor: this.state.borderColors.repeatOption}}>
                    
                    <label className="create-event-repeat-radio-label">
                        <input type="radio" checked={weekly} onChange={() => { this.props.changeRepeatFrequency('weekly'); this.resetError('repeatOption'); }} />
                        <span className='margin-left-5px'>Weekly</span>
                    </label>

                    <label className="create-event-repeat-radio-label" style={{borderColor: this.state.borderColors.repeatOption}}>
                        <input type="radio" checked={fortnightly} onChange={() => { this.props.changeRepeatFrequency('fortnightly'); this.resetError('repeatOption'); }} />
                        <span className='margin-left-5px'>Fortnightly</span>
                    </label>

                    <label className="create-event-repeat-radio-label" style={{borderColor: this.state.borderColors.repeatOption}}>
                        <input type="radio" checked={monthly} onChange={() => { this.props.changeRepeatFrequency('monthly'); this.resetError('repeatOption'); }} />
                        <span className='margin-left-5px'> Monthly</span>
                    </label>
                </div>
            </>
        );
    }



    displayRepeatEventSwitch = () => {  
        const repeats = this.props.values.repeats.repeats;
        return (
            <>
                <div className="create-event-repeats-switch-container">
                    <label>Event repeats</label>
                    <label className="switch">
                        <input type="checkbox" checked={repeats} onChange={(e) => this.props.toggleEventRepeatsSwitch(e,)} />
                        <span className="slider round">
                            <span className={`switch-label ${repeats ? 'yes' : 'no'}`}>{repeats ? 'Yes' : 'No'}</span>
                        </span>
                    </label>
                </div>
    
                
            </>
        );
    }
    




    
    render() {
        const {values} = this.props

        return (
            <>
                <form className="create-event-form">
                <div className="create-event-heading">
                    <header>Event Details</header>
                    <hr />
                </div>    

                <p className='create-event-warning' id="title">{this.state.errors.title}</p>
                    <input
                        required
                        value={values.title}
                        onChange={event => this.handleFieldChange(event, 'title')}
                        onBlur={event => this.checkTitleError(values.title)}
                        type='text'
                        placeholder='Event Name'
                        style={{borderColor: this.state.borderColors.title}}
                        
                    />
                    <p className='create-event-warning' id="startDetails">{this.state.errors.startDetails}</p>
                    <div className="datePickerDiv" style={{borderColor: this.state.borderColors.startDetails }}
                        onMouseEnter={() => {this.enableStartDetailsInput()}}
                    >

                        <DatePicker
                            id="datePicker"
                            timeIntervals={15}
                            selected={Date.parse(values.startDetails)}
                            onChange={event => this.handleFieldChange(event, 'startDetails')}   
                            onCalendarClose={(event) => {this.checkForDateErrors()}}
                            showTimeSelect
                            dateFormat="d MMM yyyy, HH:mm"
                            required
                            placeholderText={'Date & Time Event Starts'}
                            style={{borderColor: this.state.borderColors.startDetails}}
                            autoComplete="off"
                            disabled={this.state.disableStartDetails}
                        />
                    </div>
                    <p className='create-event-warning' id="endDetails">{this.state.errors.endDetails}</p>
                    <div 
                        className="datePickerDiv" style={{borderColor: this.state.borderColors.endDetails }}
                        onMouseEnter={() => {this.enableEndDetailsInput()}}
                        >
                        <DatePicker
                            id="datePicker"
                            timeIntervals={15}
                            selected={Date.parse(values.endDetails)}
                            onChange={event => this.handleFieldChange(event, 'endDetails')}
                            onCalendarClose={event => this.checkForDateErrors()}
                            showTimeSelect
                            dateFormat="d MMM yyyy, HH:mm"
                            required
                            placeholderText={'Date & Time Event Ends'}
                            style={{borderColor: 'purple'}}
                            autoComplete="off"
                            disabled={this.state.disableEndDetails}

                        />
                    </div>
                    <p className='create-event-warning' id="eventPassword">{this.state.errors.eventPassword}</p>
                    <input
                        value={values.eventPassword}                  
                        onChange={event => this.handleFieldChange(event, 'eventPassword')}
                        onBlur={() =>this.checkPasswordError(values)}
                        type='password'
                        placeholder='Password To Check Customers In'
                        style={{borderColor: this.state.borderColors.eventPassword}}
                    />          
                    <p className='create-event-warning' id="description">{this.state.errors.description}</p>
                    <textarea
                        value={values.description}
                        required
                        minLength="6"
                        onChange={event => this.handleTextAreaChange(event)}
                        onFocus={event => this.handleTextAreaChange(event)}
                        onBlur={event => this.checkDescriptionError(event)}
                        type='text'
                        placeholder='Describe The Event'
                        style={{borderColor: this.state.borderColors.description, height: this.state.textAreaHeight}}
                    />
                    <br />



                    <p className='create-event-warning' id="eventParticipants">{this.state.errors.eventParticipants}</p>
                    <div className='margin-bottom-25px create-event-container-border create-event-filter-container' style={{borderColor: this.state.borderColors.eventParticipants }}>
                        <label className='center'>Event Participants</label>
                        <div className="create-event-filter-container">
                            <div >
                                <label className="create-event-filters-container">
                                <input
                                    type="checkbox"
                                    className="create-event-checkbox create-event-checkbox-space"
                                    checked={this.props.values.eventFilters.gayMen}
                                    onChange={() => {
                                        this.props.changeEventParticipants('gayMen');
                                        this.resetError('eventParticipants');
                                    }}
                                />
                                    Gay Men Only
                                </label>
                            </div>
                            <div>
                                <label className="create-event-filters-container"> 
                                    <input
                                        type="checkbox"
                                        className="create-event-checkbox create-event-checkbox-space"
                                        checked={this.props.values.eventFilters.lesbians}
                                        onChange={() => {
                                            this.props.changeEventParticipants('lesbians');
                                            this.resetError('eventParticipants');
                                        }}
                                    />
                                    Lesbians Only
                                </label>
                            </div>
                            <div>
                                <label className="create-event-filters-container"> 
                                    <input
                                        type="checkbox"
                                        className="create-event-checkbox create-event-checkbox-space"
                                        checked={this.props.values.eventFilters.trans}
                                        onChange={() => {
                                            this.props.changeEventParticipants('trans');
                                            this.resetError('eventParticipants');
                                        }}
                                    />
                                    Trans Only
                                </label>
                            </div>
                            <div>
                                <label className="create-event-filters-container"> 
                                    <input
                                        type="checkbox"
                                        className="create-event-checkbox create-event-checkbox-space"
                                        checked={this.props.values.eventFilters.everybody}
                                        onChange={() => {
                                            this.props.changeEventParticipants('everybody');
                                            this.resetError('eventParticipants');
                                        }}
                                    />
                                    All LGBT
                                </label>
                            </div>
                        </div>
                    </div>

                    
                    <p className='create-event-warning' id="eventType">{this.state.errors.eventType}</p>
                    <div className='margin-bottom-25px create-event-filter-container create-event-container-border' style={{borderColor: this.state.borderColors.eventType }}>
                        <label className='center'>Event Type</label>
                        <div >
                            <label className="create-event-filters-container">
                                <input
                                    type="checkbox"
                                    className="create-event-checkbox create-event-checkbox-space"
                                    checked={this.props.values.eventFilters.sport}
                                    onChange={() => {
                                        this.props.changeEventType('sport');
                                        this.resetError('eventType');
                                    }}
                                />
                                Sport
                            </label>
                        </div>
                        <div>
                            <label className="create-event-filters-container"> 
                                <input
                                    type="checkbox"
                                    className="create-event-checkbox create-event-checkbox-space"
                                    checked={this.props.values.eventFilters.social}
                                    onChange={() => {
                                        this.props.changeEventType('social');
                                        this.resetError('eventType');
                                    }}
                                />
                                Social
                            </label>
                        </div>
                        <div>
                            <label className="create-event-filters-container"> 
                                <input
                                    type="checkbox"
                                    className="create-event-checkbox create-event-checkbox-space"
                                    checked={this.props.values.eventFilters.supportGroup}
                                    onChange={() => {
                                        this.props.changeEventType('supportGroup');
                                        this.resetError('supportGroup');
                                    }}                                
                                />
                                Support Group
                            </label>
                        </div>
                        <div>
                            <label className="create-event-filters-container"> 
                                <input
                                    type="checkbox"
                                    className="create-event-checkbox create-event-checkbox-space"
                                    checked={this.props.values.eventFilters.health}
                                    onChange={() => {
                                        this.props.changeEventType('health');
                                        this.resetError('eventType');
                                    }}                                
                                />
                                Health & Wellbeing
                            </label>
                        </div>
                        <div>
                            <label className="create-event-filters-container"> 
                                <input
                                    type="checkbox"
                                    className="create-event-checkbox create-event-checkbox-space"
                                    checked={this.props.values.eventFilters.barClub}
                                    onChange={() => {
                                        this.props.changeEventType('barClub');
                                        this.resetError('barClub');
                                    }}
                                />
                                Bar or Club Event
                            </label>
                        </div>                        
                    </div>

                    <div className="margin-bottom-25px">
                        <div className="margin-bottom-25px">
                            <input 
                                type="text" 
                                placeholder="Organiser Name" 
                                value={values.organiserName} 
                                onChange={(e) => this.props.changeField(e, 'organiserName')}
                            />
                        </div>
                        <div className="margin-bottom-25px">
                            <input 
                                type="url" 
                                placeholder="Organiser Website (optional)" 
                                value={values.organiserWebsite} 
                                onChange={(e) => this.props.changeField(e, 'organiserWebsite')}
                            />
                        </div>
                        <div className="margin-bottom-25px">
                            <input 
                                type="url" 
                                placeholder="Link to Event Website (optional)" 
                                value={values.eventWebsite} 
                                onChange={(e) => this.props.changeField(e, 'eventWebsite')}
                            />
                        </div>
                    </div>

                    {this.displayRepeatEventSwitch()}
                    {this.declareDayOfEvent(values.startDetails, values.repeats.repeats)}
                    {this.displayRepeatFrequencyOptions()}
                    {this.declareMonthlyFrequency()}

          
                <div style={this.spinnerVisibility()}   className ='ticket-spinner'>
                    
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
                <div>{this.props.message}</div>
                    <button className={this.disableOrEnableButton()} onClick={(event) => this.continue(event, values)}>{this.props.buttonText}</button>
                </form>
            </>
        )
    }
}

export default EventDetails


