
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSlidersH
} from "@fortawesome/free-solid-svg-icons";

import "../Styles/FilterOptions.css";




class FilterOptions extends React.Component {


    displayFilterIcon = () => {
        if(this.props.nav){return}
        return(
            <button className="filter-filter-button">
                <FontAwesomeIcon icon={faSlidersH} /> Filters
            </button>
        )
    }

    gayMenFilterButton = () => {
      return (
        <button 
          className={this.props.filters.gayMen ? "filter-circular-button" : "filter-circular-button-deselected"} 
          onClick={() => this.props.toggleFilters("gayMen")}
        >
          Gay Men+ {this.props.filters.gayMen && <span className="filter-close-icon">x</span>}
        </button>
      );
    }

    lesbianFilterButton = () => {
      return (
        <button 
          className={this.props.filters.lesbian ? "filter-circular-button" : "filter-circular-button-deselected"} 
          onClick={() => this.props.toggleFilters("lesbian")}
        >
          Lesbian+ {this.props.filters.lesbian && <span className="filter-close-icon">x</span>}
        </button>
      );
    }

    render() {
      return (
        <div className="filter-container ">
            {this.displayFilterIcon()}
            <div className="filter-options">
              {this.gayMenFilterButton()}
              {this.lesbianFilterButton()}
            </div>

        </div>
      )    
    }
  }

export default FilterOptions;