import React from "react";
import axios from "axios";
import moment from "moment";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "./CheckoutForm";
import ColmTicket from "./ColmTicket";
import Nav from "./Nav";
import { Helmet } from 'react-helmet';
import Footer from "./Footer";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faMapMarker,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Event.css";
import "../Styles/DatePickerEdits.css";
import "../Styles/Global.css";

class Event extends React.Component {


  state = {
    formFields: [{ label: "Number to Buy", type: "number", value: "price" }],
    userEvent: {
      _id: "",
      image: "",
      region: "dublin",
      lat: 53.34723555464459,
      lng: -6.258907671241786,
      title: "",
      venue: "",
      address1: "",
      address2: "",
      address3: "",
      address4: "",
      confirmEmail: "",
      description: "",
      startDetails: "",
      endDetails: "",
      organiser: { _id: "", name: "", stripeAccountID: "", salesPlan: "" },
      currency: "EUR",
      tickets: [
        {
          ticketType: "",
          ticketTypeID: "",
          price: "",
          numberOfTickets: "",
          sellWhenPrevSoldOut: "",
          startSelling: "",
          stopSelling: "",
          startSellingTime: "",
          stopSellingTime: "",
          ticketDescription: "",
          quantityRequested: 1,
          refunds: {
            optionSelected: "excessDemand",
            refundUntil: "",
            howToResell: "auction",
            resellAtSpecificPrice: "",
            minimumPrice: "",
            nameOfResoldTickets: "lastMinuteTickets",
          },
        },
      ],
      fixedCharge: '',
      variableCharge: '',
    },
    currency: {
      USD: "$",
      EUR: "€",
      NZD: "$",
    },
    purchaserEmail: '',
    cardCountry: 'EU',
    stripeCustomerID: "",
    errorMsg: "",
    lastMinuteTicketCharges: {
      subTotal: 0,
      fixedCharge: 0,
      variableCharge: 0,
      vatOnCharges: 0,
    },
    purchaseTicketCharges: {
      subTotal: 0,
      fixedCharge: 0,
      variableCharge: 0,
      vatOnCharges: 0,
    },
    message: "",
    checkBoxes: {
      promotionalMaterial: false,
      holdOnCard: false,
      chargeForNoShows: false,
      waitList: false,
      shareData: false
    },
    displayRefundStatus: false,
    errors: [],
    allTicketsSoldOut: false,
    specificWaitListDateError: '',
    getTicketsAttempted: false,
    purchaseTicketsAttempted: false,
    requiredPurchaserDetails: [],
    pageLoading: true,
    imageLoading: true,

  };

  componentDidMount() {
    let token = "";
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    }
    let objectToSend = {
      token: token,
      specificEvent: this.props.match.params.id,
    };

    axios
      .post(`${process.env.REACT_APP_API}/retrieveEventByID`, objectToSend)
      // .post(`https://v4fyl0btyc.execute-api.eu-west-1.amazonaws.com/prod/retrieveEventByID`, objectToSend)

      .then((res) => {
        let allTicketsSoldOut = res.data.userEvent.tickets.every(e=>e.soldOut)
        if(res.data.userEvent.tickets.length === 0){allTicketsSoldOut = true}   
        if(res.data.userEvent.tickets.filter(e=>e.lastMinuteTicket).length > 0){allTicketsSoldOut = true}    
        this.setState({
          purchaserEmail: res.data.purchaserEmail,
          confirmEmail: res.data.purchaserEmail,
          userEvent: res.data.userEvent,
          allTicketsSoldOut: allTicketsSoldOut,
          requiredPurchaserDetails: res.data.userEvent.requiredPurchaserDetails,
          pageLoading: false,
        });
      })
      .catch((err) => console.log("errr", err));
  }

  calculateSubTotal = () => {
    let tickets = this.getRequestedTickets()
    if(tickets.length === 0){return 0}
    let subTotal = tickets.map(ticket => ticket.quantityRequested*ticket.price).reduce((a,b) => a+b)
    return subTotal
  }

  calculateTotalCharge = () => {
    let tickets = this.getRequestedTickets()
    if(tickets.length === 0){return 0}
    let totalCharge = this.calculateSubTotal() + this.calculateFees()
    let rounded = this.roundToTwo(totalCharge)
    return rounded
   }

  calculateFees = () => {
    let tickets = this.getRequestedTickets()
    if(tickets.length === 0){return 0}
    let totalFeesArray = []
    tickets.forEach(ticket => {
      if(ticket.price === 0){totalFeesArray.push(0); return}
      let fixedCharge = this.state.userEvent.fixedCharge
      let variableCharge = this.state.userEvent.variableCharge
      let feePerTicket = this.roundToTwo(fixedCharge + (ticket.price*variableCharge))
      let chargeExVat = this.roundToTwo(feePerTicket * ticket.quantityRequested)
      totalFeesArray.push(chargeExVat)
    })
    let totalFeesExVat= totalFeesArray.reduce((a,b)=> a+b)
    let vatOnCharges = this.roundToTwo(0.23*(totalFeesExVat))
    return totalFeesExVat + vatOnCharges
  }

  calculateTotalFines = (tickets) => {
    return tickets
    .map(ticket => ticket.quantityRequete * ticket.chargeForNoShows)
    .reduce((a, b) => a + b);
  }

  changeCardCountry = (event) => {
    let cardCountry = event.target.value
    this.setState({cardCountry})
  }

  changeConfirmedEmail = (event) => {
    let confirmEmail = event.target.value
    this.setState({ confirmEmail })
  }

  changeEmail = (event) => {
    let purchaserEmail = event.target.value
    this.setState({ purchaserEmail })
  }

  changeQuantity = (index, quantity, clearMessage, resetGetTicketAttemps=true) => {
    let userEvent = this.state.userEvent;
    userEvent.tickets[index].quantityRequested = quantity;
    this.setState({ userEvent });
    if(clearMessage){ 
      this.setState({ message: "" })  
      this.hideRefundStatus()
    }
    this.resetWaitListCheckBox()
    if(resetGetTicketAttemps){
      //this makes the credit card input and other purchase related fields disappear if they are not required by the new selection of tickets
      let getTicketsAttempted = false
      let purchaseTicketsAttempted = false
      this.setState({getTicketsAttempted, purchaseTicketsAttempted });

    }
  }



  changeWaitListExpiration = (event, index) => {
    let userEvent = this.state.userEvent
    userEvent.tickets[index].waitListExpires = event.target.value;
    this.resetWaitListCheckBox()
    this.setState(userEvent)
  }

  checkBoxesAreTicked = () => {
    let checkBoxErrors = [];
    checkBoxErrors = this.waitListCheckBoxTicked(checkBoxErrors)
    if(!this.state.checkBoxes.shareData){
      checkBoxErrors.push('shareData')
    }
    if (checkBoxErrors.length > 0) {
      let errors = checkBoxErrors;
      this.updateMessage("Please agree to all mandatory terms and conditions", 'red');
      this.setState({ errors });
      return false;
    } 
      this.clearMessage();
      return true;
  }

  clearMessage = () => {
    this.setState({ message: "" });
  }

  customerDetailsInComplete = async () => {
    if(!this.emailAddressIsValid()){
      this.updateMessage("Please enter a valid email address", 'red')
      let errors = "purchaserEmail"
      this.setState({ errors })
      return true}
    if(this.state.purchaserEmail !== this.state.confirmEmail){
      this.updateMessage("Your email addresses do not match", 'red')
      let errors = "confirmEmail"
      this.setState({ errors,  })
      return true}

      let requiredPurchaserDetailsArray = await Promise.all(
        //can't use forEach. Won't work with asycn await. Function is called with async await function
        this.state.requiredPurchaserDetails.map((input, index) => {
          if(input.value === ''){
            this.updateMessage("Please provide more information", 'red')
            let errors = input.valueName
            this.setState({ errors })
        


            let element = document.getElementsByClassName('requiredPurchaserDetail')[index]
            element.style.borderColor = "red";
            document.getElementsByClassName('requiredPurchaserDetail')[index].scrollIntoView({behavior: "smooth"})

            return true
          }
        })
      )

      if(requiredPurchaserDetailsArray.includes(true)){return true}


      return false

  }

  determineCheckBoxes = () => {

    let checkBoxes = []
    let selectedTickets = this.getRequestedTickets()
    let waitListTickets = selectedTickets.filter(e=>e.lastMinuteTicket)

    if(waitListTickets.length > 0){
      checkBoxes.push({
        text: `I agree that my card can be charged €${this.calculateTotalCharge().toFixed(2)} before ${this.waitListExpirationTime(waitListTickets)}`,
        checkBox: "waitList",
      })
    }

    if(this.state.userEvent._id !== '633f03d90e832b05ea902dfc'){
      //this is a specific request for lama
      checkBoxes.push({
        text: `Keep me updated on more events and news from the event organiser`,
        checkBox: "promotionalMaterial",
      })
    }



    checkBoxes.push({
      text: `I agree to share my data with the event organiser`,
      checkBox: "shareData",
    })

    return checkBoxes
  }

  displayAdminFee = () => {
    return (
      this.state.purchaseTicketCharges.fixedCharge +
      this.state.purchaseTicketCharges.variableCharge +
      this.state.purchaseTicketCharges.vatOnCharges
    ).toFixed(2);
  }

  displayChargesBreakdown = (subTotal, fees, totalCharge) => {

    if(Number(totalCharge) === 0){return}
    if(this.getRequestedTickets().length === 0 && !this.state.getTicketsAttempted){return}
    return(              
    <div className="event-checkbox-wrapper">
      <div> Subtotal: €{subTotal}</div>
      <div>Fees: €{fees}</div>
      <div className="event-total-charge">Total: €{totalCharge}</div>
      <hr id="event-total-charge-bottom-hr" />
    </div>
  )
  }

  displayCheckBoxes = () => {
    // if(this.getRequestedTickets().length === 0 && !this.state.getTicketsAttempted){return}
    let checkBoxes = this.determineCheckBoxes()
    return checkBoxes.map((e, i) => 
        <div
          key={i}
          className={`event-check-box-question   ${this.state.errors.includes(e.checkBox) ? "colorRed" : ""}`}
        >
          <input
            type="checkbox"
            name={e.checkBox}
            checked={this.state.checkBoxes[e.checkBox]}
            onChange={(event) => this.handleCheckBoxChange(event)}
          />
          {` ${e.text}`}
        </div>
    )
  }

  displayConfirmEmail = () => {
    if(this.isUserLoggedIn()){return}
    // if(this.getRequestedTickets().length === 0 && !this.state.getTicketsAttempted){return}
    return (              
      <input type="email" placeholder="Confirm Email Address" name="email"
        required
        value={this.state.confirmEmail}
        onChange={event => this.changeConfirmedEmail(event)}
        className={'match-stripe'}
        autoComplete={'on'}
      />
    )
  }

  displayEmailInput = () => {
    // if(this.getRequestedTickets().length === 0 && !this.state.getTicketsAttempted){return}
    return (   
    <input type="email" placeholder="Your Email Address" name="email"
      required
      value={this.state.purchaserEmail}
      disabled = {this.isUserLoggedIn()}
      onChange={event => this.changeEmail(event)}
      className={'match-stripe'}
      autoComplete={'on'}
    />
    )
  }

  changeRequiredPurchaserDetail = (event, field, index) => {
    console.log('field', field)
    console.log('index', index)
    console.log('event.target.value', event.target.value)

    let element = document.getElementsByClassName('requiredPurchaserDetail')[index]
    console.log('element', element)
    element.style.borderColor = "#ececec";
    let requiredPurchaserDetails = this.state.requiredPurchaserDetails
    const relevantIndex = requiredPurchaserDetails.findIndex(e => e.valueName === field);
    requiredPurchaserDetails[relevantIndex].value = event.target.value
    this.setState({requiredPurchaserDetails})

  }

  displayOtherInputs = () => {
    if(this.state.requiredPurchaserDetails.length === 0){return}
    // if(this.getRequestedTickets().length === 0 && !this.state.getTicketsAttempted){return}

    let requiredPurchaserDetails = this.state.requiredPurchaserDetails.map((e,i) => {e.originalIndex = i; return e})

    return (
      <>
      {/* requiredPurchaserDetails.filter(e => e.type !== 'radio').map */}
        {requiredPurchaserDetails.map((e) => {return (
          <input type="text" placeholder={e.valueName} name={e.valueName}
            key={e.originalIndex}
            required
            value={e.value}
            onChange={event => this.changeRequiredPurchaserDetail(event, e.valueName, e.originalIndex)}
            className={'match-stripe requiredPurchaserDetail'}
            autoComplete={'on'}
          />
      )}
      
      )
      
      }
      </>
    )
  }

  displayRadioInputs = () => {
    return
    let requiredPurchaserDetails = this.state.requiredPurchaserDetails.map((e,i) => {e.originalIndex = i; return e})
    let radioInputs = requiredPurchaserDetails.filter(e => e.type === 'radio')
    return (
    radioInputs.map((radioInput, i )=> {
      
      return (
      <div key={i} className="event-custom-query-radio-wrapper requiredPurchaserDetail">
        <div className="event-custom-query-radio-question">{radioInput.valueName}</div>
        <div>
          {radioInput.options.map((option, j )=> {
    

            console.log('radioInput.originalIndex', radioInput.originalIndex)
            console.log('option', option)
            console.log('this.state.requiredPurchaserDetails[radioInput.originalIndex].value', this.state.requiredPurchaserDetails[radioInput.originalIndex].value)
            return(
            <div key={j}>
              <input
                type="radio"
                checked={this.state.requiredPurchaserDetails[radioInput.originalIndex].value===option}
                value={option}
                onChange={event => this.changeRequiredPurchaserDetail(event, radioInput.valueName, radioInput.originalIndex)}
                // onChange={event => this.props.changeCardCountry(event)}
              />
                {` ${option}`}
        
            </div>)})}
          </div>
      </div>)})
    )
  }


  displaySoldOutMessage = () => {
    if(this.state.userEvent.tickets.length === 0){return}
    if(this.state.userEvent.tickets[0].ticketType === ''){return}
    let waitListExists = false
    if(this.state.userEvent.tickets.filter(e=>e.lastMinuteTicket).length > 0){waitListExists = true}
    if(!this.state.allTicketsSoldOut && !waitListExists){return}

    return(
      <div className={'sold-out-message'}>
        Sorry, all tickets are sold out
      </div>
    )
  }

  displaySpecificDate = (event, index) => {
    let specificWaitListDateError = ''
    let userEvent = this.state.userEvent
    userEvent.tickets[index].waitListSpecificDate = event;
    if(moment(event).isAfter(userEvent.tickets[0].stopSellingTime)){
      userEvent.tickets[index].waitListSpecificDate = Date.parse(userEvent.tickets[0].stopSellingTime)
      specificWaitListDateError = `The latest time you can secure a ticket is ${moment(userEvent.tickets[0].stopSellingTime).format('Do MMMM YYYY [at] HH:mm')}`
    }
    this.resetWaitListCheckBox()
    this.setState({userEvent, specificWaitListDateError})
  }

  displayStartAndEndTimes = () => {
    let startDay = moment(this.state.userEvent.startDetails).format("ddd D MMM YYYY")
    let startTime = moment(this.state.userEvent.startDetails).format("HH:mm")
    let endDay = moment(this.state.userEvent.endDetails).format("ddd D MMM YYYY")
    let endTime = moment(this.state.userEvent.endDetails).format("HH:mm")
    if(startDay === endDay){return `${startDay} from ${startTime} until ${endTime}`}
    return `${startDay} at ${startTime} until ${endDay} at ${endTime}`
  }

  displayTickets = () => {

    if(this.state.userEvent.tickets.length === 0){return}
    if(this.state.userEvent.tickets[0].ticketType === ''){return}
    let tickets = this.state.userEvent.tickets

    return (
      
      tickets.map((ticket, index) => {
      return (
        <div key={index}>
          <ColmTicket
            changeDeliveryOption = {this.changeDeliveryOption}
            changeQuantity={this.changeQuantity}
            changeWaitListExpiration={this.changeWaitListExpiration}
            displaySpecificDate={this.displaySpecificDate}
            index={index}
            ticket = {JSON.parse(JSON.stringify(ticket))}
            waitListSpecificDate = {ticket.waitListSpecificDate}
            ticketsAvailable = {ticket.ticketsAvailable}
            specificWaitListDateError = {this.state.specificWaitListDateError}
          />
        </div>
      );
    }))    
  }

  emailAddressIsValid = () => {
    if(this.state.purchaserEmail === undefined){return false}
    let emailAddressArray = this.state.purchaserEmail.split("")
    if(emailAddressArray[0]==='@'){return false}
    if(!emailAddressArray.includes('@')){return false}
    if(emailAddressArray[emailAddressArray.length-1]==='@'){return false}
    return true
  }

  getConnectedCheckoutForm = () => {

    if(this.state.userEvent.organiser.stripeAccountID === ""){return}
    let requestedTickets = this.getRequestedTickets()
    // if(requestedTickets.length === 0 && !this.state.getTicketsAttempted){return}

    return (
      <StripeProvider
        apiKey={process.env.REACT_APP_API_STRIPE_PUBLISH}
        stripeAccount={
          this.state.userEvent.organiser.stripeAccountID 
          // || undefined
        }
        // key={this.state.checkBoxes.saveCard || "platform"}
      >
        <Elements>
          <CheckoutForm
            cardCountry = {this.state.cardCountry}
            changeCardCountry = {this.changeCardCountry}
            changeEmail = {this.changeEmail}
            changeQuantity={this.changeQuantity}
            clearMessage = {this.clearMessage}
            displayCheckBoxes = {this.displayCheckBoxes} 
            displayRefundStatus={this.state.displayRefundStatus}
            message ={this.state.message}
            promotionalMaterial={this.state.checkBoxes.promotionalMaterial}
            purchaserEmail={this.state.purchaserEmail}
            purchaseErrorsExist={this.purchaseErrorsExist}
            purchaseTicketsAttempted={this.state.purchaseTicketsAttempted}
            recordAttemptToGetTickets={this.recordAttemptToGetTickets}
            requestedTickets={requestedTickets}
            resetTicketQuantities={this.resetTicketQuantities}
            tickets={this.getRequestedTickets()}
            toggleRefundStatusDisplay = {this.toggleRefundStatusDisplay}
            total={this.calculateTotalCharge()}
            updateMessage={this.updateMessage}
            userEvent={this.state.userEvent}
            requiredPurchaserDetails={this.state.requiredPurchaserDetails}
          />
        </Elements>
      </StripeProvider>
    );

  }

  getLocation = (userEvent) => {
    let locationArray = [
      userEvent.venue,
      userEvent.address1,
      userEvent.address2,
      userEvent.address3,
      userEvent.address4,
    ]
    locationArray = locationArray.filter(e => e!== '')
    return locationArray.join(", ");
  }

  getRequestedTickets = () => {
    let selectedTickets = this.state.userEvent.tickets.filter(ticket => ticket.quantityRequested > 0)
    return selectedTickets
  }

  getUnconnectedCheckoutForm = (requestedTickets) => {
    if(this.state.userEvent.organiser.stripeAccountID === ""){return}
    return (
      <StripeProvider apiKey={process.env.REACT_APP_API_STRIPE_PUBLISH}>
        <Elements>
          <CheckoutForm
            total={this.calculateTotalCharge()}
            ticketTypesEquivalent={this.state.userEvent.ticketTypesEquivalent}
            currency={this.state.userEvent.currency}
            eventTitle={this.state.userEvent.title}
            userEvent={this.state.userEvent}
            updateMessage={this.updateMessage}
            requestedTickets={requestedTickets}
            errors={this.state.errors}
            selectedTickets={this.getRequestedTickets()}
            purchaseErrorsExist={this.purchaseErrorsExist}
            connected={false}
            cardDetails={this.state.cardDetails}
          />
        </Elements>
      </StripeProvider>
    );
  }

  handleCheckBoxChange = (e) => {
    let checkBoxes = this.state.checkBoxes;
    let errors = this.state.errors;

    let indexOfError = errors.indexOf(e.target.name);

    if (indexOfError !== -1) {
      errors.splice(indexOfError, 1);
    }
    this.hideRefundStatus()
    checkBoxes[e.target.name] = !checkBoxes[e.target.name];
    this.setState({ checkBoxes, errors });
    if (this.state.errors.length === 0) {
      this.clearMessage();
    }
  }

  hideRefundStatus = () => {
    let displayRefundStatus = false
    this.setState({displayRefundStatus})
  }

  insufficentTickets = (selectedTickets) => {
    let insufficentTickets = selectedTickets.find(ticket => Number(ticket.quantityRequested) > ticket.ticketsAvailable)
    if(insufficentTickets === undefined){return false}
    let message
    if(insufficentTickets.ticketsAvailable > 1){message = `You have selected ${insufficentTickets.quantityRequested} ${insufficentTickets.ticketType} tickets but only ${insufficentTickets.ticketsAvailable} are available`}
    else{message = `You have selected ${insufficentTickets.quantityRequested} ${insufficentTickets.ticketType} tickets but only 1 ticket is available`}
    this.updateMessage(message, 'red')
    let errors = "insufficientTickets"
    this.setState({ errors })
    return true
  }

  isUserLoggedIn = () => {
    let token = localStorage.getItem("token");
    if (token) {return true} 
    return false; 
  }


  noTicketsSelected = () => {
    let numberOfTickets = this.state.userEvent.tickets.map(ticket => Number(ticket.quantityRequested)).reduce( (a,b) => a+b )
    if (numberOfTickets === 0) {
      let message = "Please enter the quantity of tickets that you want to purchase"
      this.updateMessage(message, 'red')
      let errors = "quantity"
      this.setState({ errors })
      return true
    }
  }


  purchaseErrorsExist = async () => {
    let selectedTickets = this.getRequestedTickets()
    if(this.noTicketsSelected()){return true}
    if(await this.customerDetailsInComplete()){return true}
    if(this.insufficentTickets(selectedTickets)){return true}
    if(!this.checkBoxesAreTicked(selectedTickets)){return true}
    return false
  }

  recordAttemptToGetTickets = (total) => {
    let getTicketsAttempted = true
    let purchaseTicketsAttempted
    total === 0 ? purchaseTicketsAttempted = false : purchaseTicketsAttempted = true
    this.setState({getTicketsAttempted, purchaseTicketsAttempted})
  }

  resetTicketQuantities = () => {
    let tickets = this.state.userEvent.tickets
      tickets.forEach((ticket, index) => {
      this.changeQuantity(index, 0, false, false)
    })
  }

  resetWaitListCheckBox = () => {
    let selectedTickets = this.getRequestedTickets()
    if(selectedTickets.filter(e=>e.lastMinuteTicket).length === 0){return}
    let checkBoxes = this.state.checkBoxes
    checkBoxes.waitList = false
    this.setState({  checkBoxes })
  }

  roundToTwo = (num) => {    
    return +(Math.round(num + "e+2")  + "e-2");
  }


  toggleRefundStatusDisplay = () => {
    let displayRefundStatus = this.state.displayRefundStatus
    displayRefundStatus = !displayRefundStatus
    this.setState({displayRefundStatus})
  }


  updateMessage = (msg, color) => {
    let messageColor;
    if(color ==='blue'){messageColor = '#293d6a'}
    else if(color === 'red'){messageColor = '#e84c3d'}
    else if (color === 'green'){messageColor= '#1c7816'}
    const sentences = msg.split(/(?<=\. )/).map((sentence, index) => 
      <p key={index} style={{color: messageColor}}>{sentence.trim()}</p>
    );
    this.setState({ message: sentences });
  }

  waitListExpirationTime = (waitListTickets) => {
    let time
    if(waitListTickets[0].waitListExpires === 'specific'){
      time = waitListTickets[0].waitListSpecificDate
    }
    else if(waitListTickets[0].waitListExpires === 'starts'){
      time = this.state.userEvent.startDetails
    }
    else if(waitListTickets[0].waitListExpires === 'hourBeforeEnds'){
      time = Date.parse(this.state.userEvent.endDetails) - 60*60*1000
      //fix this

    }
    if(moment(time).isAfter(waitListTickets[0].stopSellingTime)){
      time = waitListTickets[0].stopSellingTime
    }

    return `${moment(time).format('Do MMMM YYYY')} at ${moment(time).format('HH:mm')}`
  }

  waitListCheckBoxTicked = (checkBoxErrors) => {
    let selectedTickets = this.getRequestedTickets()
    let waitListTickets = selectedTickets.filter(e => e.lastMinuteTicket)
    if (waitListTickets.length> 0 && !this.state.checkBoxes.waitList){
      checkBoxErrors.push('waitList')
    }
    return checkBoxErrors
  }

  getOrganiserName = () => {
    if(this.state.userEvent._id === undefined){return}
    return ` ${this.state.userEvent.organiserName}`
  }

  displayPaymentForm = (subTotal, fees, totalCharge) => {
    let waitListExists = false
    if(this.state.userEvent.tickets.filter(e=>e.lastMinuteTicket).length > 0){waitListExists = true}
    if(this.state.allTicketsSoldOut && !waitListExists){return}
    return (   
      <form className='all-ticket-details'>
            {this.displayChargesBreakdown(subTotal, fees, totalCharge)}
            {this.displayRadioInputs()}
            {this.displayOtherInputs()}
            {this.displayEmailInput()}
            {this.displayConfirmEmail()}
            {this.getConnectedCheckoutForm()}
        </form>)
  }

  displayTicketsSection = () => {
    if(this.state.userEvent.tickets.length === 0){return}
    if(this.state.userEvent.tickets[0].ticketType === ''){return}
    let fees = this.calculateFees(this.state.userEvent.tickets).toFixed(2)  
    let subTotal = this.calculateSubTotal().toFixed(2)
    let totalCharge = this.calculateTotalCharge().toFixed(2)
    return(
      <section className="event-tickets">
        <header>Tickets</header>
        <hr />
        {this.displaySoldOutMessage()}
        {this.displayTickets()}
        <hr />
        {this.displayPaymentForm(subTotal, fees, totalCharge)}
      </section>
    )
  }

  displayLocation = () => {
    let location = this.getLocation(this.state.userEvent)
    if(location === ''){return}
    return (
      <p>
        <a href={`https://www.google.com/maps/search/?api=1&query=${this.state.userEvent.lat},${this.state.userEvent.lng}`}
          target="_blank"
          rel="noopener noreferrer">
          <FontAwesomeIcon icon={faMapMarker} className="fontawesome-icon"/>{` ${location }`}
        </a>
      </p>
    )
  }

  toggleFilters = (filter) =>{
    let filters = this.state.filters
    filters[filter] = !filters[filter]
    this.setState({filters})
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{this.state.userEvent.title}</title>
          <meta name="description" content={this.state.userEvent.description} />
          <meta property="og:title" content={this.state.userEvent.title} />
          <meta property="og:description" content={this.state.userEvent.description} />
          <meta property="og:image" content={this.state.userEvent.imageURL} />
        </Helmet>
        <Nav />
        <div className={this.state.pageLoading ? "event-page-loading" : "display-none"}>
          <div className ={'lds-default'}>
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
          </div>
        </div>
          <div className={this.state.pageLoading ? "display-none" : "event-box"}>
            <img src={this.state.userEvent.imageURL} alt={this.state.userEvent.title}/>
            <section className="event-details">
              <div className="event-center">
                <header>{this.state.userEvent.title}</header>
                <hr />
                <div className="event-time-location-organiser">
                  <p><FontAwesomeIcon icon={faCalendarAlt} className="fontawesome-icon"/>{` ${this.displayStartAndEndTimes()}`} </p>
                  {this.displayLocation()}
                  <p><FontAwesomeIcon icon={faUser} className="fontawesome-icon" />{this.getOrganiserName()}</p>
                </div>
              </div>
            </section>
          </div>
          <div className={this.state.pageLoading ? "display-none" : "event-description-and-tickets-wrapper"}>
            <section className="event-description">
              <header>Details</header>
              <hr />
              <div className="center-text">{this.state.userEvent.description}</div>
              
            </section>
            {this.displayTicketsSection()}
          </div>

        
        <Footer />
      </>
    );
  }
}

export default Event;






    
 



