import React, {useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "../Styles/ColmsTicket.css";
import axios from 'axios';
import TicketSummary from "./TicketSummary";
var QRCode = require('qrcode.react')



const PurchasedTicket = (props) =>{

    function cancelRefundRequest(){
        setDisplaySpinner(true)
        setMessage('Please Wait...')
        let objectToSend = {token: localStorage.getItem("token"), ticket: ticket}
        axios.post(`${process.env.REACT_APP_API}/cancelRefundRequest`, objectToSend)
        .then(res => {
            setMessage(res.data.message)
            setDisplaySpinner(false)
            if(!res.data.error){setTicket(res.data.ticket)}  
        }
        
        )
    }

    function displayCancelRefundButton(){
        if(ticket.refunded){return}
        if(ticket.refunds.optionSelected==='noRefunds'){return}
        if(!ticket.refundRequested){return}
        if(ticket.refunds.optionSelected!=='excessDemand'){return}
        return <button onClick={event => cancelRefundRequest()}>Cancel Refund Request</button>
    }



    function displayRequestRefundButton(){
        if(props.requestFromOrganiser!==true){
            if(ticket.refundRequested && ticket.refunds.optionSelected==='excessDemand'){return}
            if(ticket.refunds.optionSelected==='noRefunds'){return}
            if(ticket.refunds.optionSelected==='doNotDisplay'){return}
            if(moment().isAfter(ticket.refunds.refundUntil)){return}
        }
        if(displaySpinner){return}
        if(ticket.refunded){return}
        return <button onClick={event => requestRefund()}>Request Refund</button> 
    }

    function displayCheckInButton(){
        if(ticket.checkedIn){return}
        if(ticket.refunded){return}
        return <button onClick={event => checkIn()}>Check In Ticket</button> 
    }





    function getPaidTicketCode(code){
        
        getPriceCode(code)
        getTicketTypeCode(code)
        getRefundPolicyCode(code)

    }

    function getPriceCode(code){
        let price
        Number(ticket.price) === 0 ? price = 'Free' : price = `€${ticket.price}`
        let priceToArray = price.split('.')
        if(priceToArray.length === 2){
            //converts €10.5 to €10.50
            
            price = `${priceToArray[0]}.${priceToArray[1]}0`  
        }
        code.push(
        <div key={code.length} className={'ticket-detail ticket-detail-price'}>
            <span>{'price'}</span>
            <h2>{price}</h2>
        </div>
        )
        return code
    }


    function getRefundPolicyCode(code){
        if(Number(ticket.price) === 0){return code}

        let refundStatus
        let refundClassName
        
        if(ticket.refunds.optionSelected === 'excessDemand'){
            refundStatus = `A limited number of refunds will be available if this event sells out`
            refundClassName = `ticket-detail ticket-detail-refund ticket-detail-refund-excess-Demand`
        }else if(ticket.refunds.optionSelected === 'untilSpecific'){
            refundStatus = `Refunds available until ${moment(ticket.refunds.refundUntil).format('Do MMMM [at] HH:mm')}`
            refundClassName = `ticket-detail ticket-detail-refund`
        }else if(ticket.refunds.optionSelected === 'noRefunds'){
            refundStatus = 'No Refunds'
            refundClassName = `ticket-detail ticket-detail-refund`
        }

        code.push(
        <div key={code.length} className={refundClassName}>
        <span>{'refund policy'}</span>
        <h2>{refundStatus}</h2>
        </div>
        )
        return code
    }

    function getTicketDetails(){
        let code = []
        getPaidTicketCode(code)
        return code
    }

    function getTicketTypeCode(code){
        code.push(
        <div key={code.length} className={'ticket-detail ticket-detail-description'}>
            <span>{'ticket type'}</span>
            <h2>{ticket.ticketType}</h2>
        </div>
        )
        return code
    }

    function requestRefund(){
        setDisplaySpinner(true)
        setMessage('Please Wait...')
        axios.post(`${process.env.REACT_APP_API}/refundRequest`, {ticket: ticket, minimumPrice: ticket.minimumPrice, token: localStorage.getItem("token")})
        .then(res => {
            setMessage(res.data.message)
            setDisplaySpinner(false)
            if(!res.data.error){setTicket(res.data.ticket)}
        })
    }

    const checkIn = () => {
        setMessageColor('#293d6a')
        setDisplaySpinner(true)
        setMessage('Please Wait...')
        axios.post(`${process.env.REACT_APP_API}/checkIn`, {qrcode: qrCode, userEvent: ticket.userEvent._id, password: props.password})
        .then(res => {
            setMessage(res.data.message)
            setDisplaySpinner(false)
            res.data.checkedIn ? setMessageColor('#2b6a29'):setMessageColor('red')
            if(res.data.checkedIn){
                ticket.checkedIn = true
                ticket.timeCheckedIn = Date.now()
                props.updateTicket(ticket, props.index)
            }
        })
   

    }

    function spinnerVisibility(){
        if(displaySpinner ){return {'display': 'block'}}
        return {'display': 'none'}
    }

    function displayQRCode(qrCode){
        return(
            <>
                <Link to={`/qr/${qrCode}`} target="_blank" rel="noopener noreferrer">
                    <QRCode 
                        value={qrCode} 
                        className='QR' 
                        style={{'maxWidth': '95%', 'height': 'auto', 'marginBottom': '12px', 'marginTop': '5px'}}/>
                </Link>
            </>
        )
    }

    function decideTicketDisplayType(){
        if(ticket === undefined){return}
        if(props.requestFromOrganiser && props.checkedInTicketsOnly !== true){return(
            <>
                <div className='ticket-detail' ref={ref}>
                    <span>{'ticket id'}</span>
                    <h2>{ticket._id}</h2>
                </div>
                <div className='ticket-detail'>
                    <span>{'customer'}</span>
                    <h2>{ticket.purchaserEmail}</h2>
                </div>
                <div className='ticket-detail'>
                    <span>{'ticket type'}</span>
                    <h2>{ticket.ticketType}</h2>
                </div>
                <div className='ticket-detail'>
                    <span>{'price'}</span>
                    <h2>{`€${ticket.price.toFixed(2)}`}</h2>
                </div>

                <div className='ticket-detail'>
                    <span>{'checked in'}</span>
                    <h2>{ticket.checkedIn?'Yes':'No'}</h2>
                </div>
                {ticket.refunded && <div className='ticket-detail'>
                    <span>{'ticket already refunded'}</span>
                    <h2>{'Yes'}</h2>
                </div>}
                <div className="ticket-buttons">{displayRequestRefundButton(true)}</div>
                
            </>)}
        if(props.requestFromOrganiser && props.checkedInTicketsOnly){
            if(props.checkInRequest){return(
                <>
                <div className='ticket-detail' ref={ref}>
                    <span>{'ticket id'}</span>
                    <h2>{ticket._id}</h2>
                </div>


                <div className='ticket-detail'>
                    <span>{'customer'}</span>
                    <h2>{ticket.purchaserEmail}</h2>
                </div>

                <div className='ticket-detail'>
                    <span>{'verification code'}</span>
                    <h2>{ticket.randomNumber}</h2>
                </div>

                <div className='ticket-detail'>
                    <span>{'ticket type'}</span>
                    <h2>{ticket.ticketType}</h2>
                </div>

                <div className='ticket-detail' ref={ref}>
                    <span>{'refunded'}</span>
                    <h2>{ticket.refunded ? 'Yes' : 'No'}</h2>
                </div>

                <div className='ticket-detail' ref={ref}>
                    <span>{'checked in'}</span>
                    <h2>{ticket.checkedIn ? 'Yes' : 'No'}</h2>
                </div>

                {ticket.checkedIn &&

                    <div className='ticket-detail' ref={ref}>
                        <span>{'time checked in'}</span>
                        <h2>{moment(ticket.timeCheckedIn).format('D MMM [at] HH:mm:ss')}</h2>
                    </div>
                }

  
                <div className="ticket-buttons">{displayCheckInButton(true)}</div>             
            </>

            )}
            
            return(
            <>
                <div className='ticket-detail' ref={ref}>
                    <span>{'ticket id'}</span>
                    <h2>{ticket._id}</h2>
                </div>
                <div className='ticket-detail' ref={ref}>
                    <span>{'time checked in'}</span>
                    <h2>{moment(ticket.timeCheckedIn).format('D MMM [at] HH:mm:ss')}</h2>
                </div>
                <div className='ticket-detail'>
                    <span>{'customer'}</span>
                    <h2>{ticket.purchaserEmail}</h2>
                </div>
               
            </>)}


        return (        
        <div className="ticket-card-wrap">
            <div className="event-card event-card-left">
                <h1 className="event-ticket-title" ref={ref}>{firstWord} <span>{restOfWord}</span></h1>
                <div className={"ticket-details"}>{getTicketDetails()}</div>
            </div>
            <div className="event-card event-card-right">
                <div className="event-eye" style={{ height: height }}>{moment(ticket.userEvent.startDetails).format('Do MMM')}</div>
                <div className="ticket-buttons">
                    {displayQRCode(qrCode)}
                    {displayRequestRefundButton()}
                    {displayCancelRefundButton()}
                </div>
            </div>
      </div>)
    }


    //each func just spits out a bit of code. then another func puts together all the code it wants for each specific ticket

    

    const [message, setMessage] = useState('')
    const [messageColor, setMessageColor] = useState('#293d6a')
    const [height, setHeight] = useState(0)
    const [displaySpinner, setDisplaySpinner] = useState(false)
    props.ticket.creationTime = new Date (props.ticket.creationTime).getTime()
    const [ticket, setTicket] = useState(props.ticket)
    let qrCode = String([ticket._id, ticket.randomNumber, ticket.creationTime, ticket.userEvent._id])
    let wordArray = ticket.userEvent.title.split(" ")
    let firstWord = wordArray[0]
    wordArray.shift()
    let restOfWord = wordArray.join(' ')
    const ref = useRef(null)

    
    useEffect(() => {setHeight(ref.current.clientHeight)})

    return (
      <div className='ticket-container'>
          {decideTicketDisplayType()}
        <div className='ticket-message-container'>
            <div style={spinnerVisibility()}   className ='ticket-spinner'>
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
            <div className='purchased-ticket-message' style={{color: messageColor}}>{message}</div>
        </div>
        {props.requestFromOrganiser && <hr className="my-event-refund-hr"/>}
      </div>
    );
}

export default withRouter(PurchasedTicket);
