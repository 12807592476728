import React from "react";
import Nav from "./Nav";
import RegisteredWaitList from "./RegisteredWaitList";
import Footer from "./Footer";
import axios from "axios";
import "../Styles/Tickets.css";


class WaitLists extends React.Component {
    state = {
        user: {
          email: "",
          _id: "",
          ticketsBought: [],
          waitLists: [],
          usersEvents: []
        },
        dataLoaded: false
      };
    
    componentDidMount() {
      let token = localStorage.getItem("token");
      axios.post(`${process.env.REACT_APP_API}/profile`, {token: token})
        .then(res => {
          this.setState({ user: res.data, dataLoaded: true})
        })    
    }



    displayWaitLists = () => {
      if(this.state.user.waitLists.length === 0){return}
      return (<div className="tickets-wrap">
        {this.state.user.waitLists.map((waitList, index)=> {
          return( <div key={index}>
              <RegisteredWaitList
                waitList = {JSON.parse(JSON.stringify(waitList))}
              />
            </div>)}
            )}
      </div>)
    }


    displayNoWaitListsMessage = () => {   
      if(!this.state.dataLoaded){return}
      if(this.state.user.waitLists.length > 0){return}      
      return (<div className="tickets-wrap center-text padding-left-10px padding-right-10px">You are not on any waitlists for upcoming events</div>)
    }

    render() {
      return (
        <>
          <Nav />
          <div className='my-event-container'>
          <div className="create-ticket-heading">
              <header>My Wait Lists</header>
              <hr />
          </div>   
        {this.displayWaitLists()}
        {this.displayNoWaitListsMessage()}
          
        </div>

          <Footer />
        </>
      )    
    }
  }

export default WaitLists;