import React from "react";
import moment from "moment";
import "../Styles/EventCard.css";





class EventCard extends React.Component {
  state = {};

  slideDirection(){
    if(this.props.index%2 === 0){return 'home-details-container home-slide-from-right'}
    return "home-details-container home-slide-from-left"
  }

  getDate = () => {
    const startDetails = moment(this.props.startDetails);
    const formattedStartDetails = startDetails.format('Do MMM');
    const today = moment().format('Do MMM');
    const tomorrow = moment().add(1, 'days').format('Do MMM');
    const nextWeek = moment().add(7, 'days');
  
    if (formattedStartDetails === today) {return 'Today'}
    if (formattedStartDetails === tomorrow) {return 'Tomorrow'}
    if (startDetails.isBetween(moment().add(1, 'days'), nextWeek, 'day', '[)')) {
      return startDetails.format('dddd');
    }
    return startDetails.format('ddd Do MMM');
  }

  render() {
    return (
    <>
    
    <div className="home-details-container">
          <div className="event-card-image-wrapper">
            <img
              src={this.props.image} alt={this.props.name}
            />
          </div>

            <div className="home-event-text-container">

              <header>{this.props.name}</header>

              <div className="home-event-date-and-location">

                <div className="home-event-date">
                  {` ${this.getDate()} @ ${moment(this.props.startDetails).format('HH:mm')} `}
                </div>

                <div className="home-event-organiserName">{this.props.organiser}</div>

              </div>
            </div>

  
        </div>
    
     
    

	</>
    )
    }
}

export default EventCard;