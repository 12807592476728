import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import '../../Styles/PurchaserDetails.css'




class PurchaserDetails extends React.Component {
  state = {
    message: '',
    noneOption: true
  };






  spinnerVisibility(){
    if(this.props.displaySpinner ){return {'display': 'block'}}
    return {'display': 'none'}
  }

  getUpdateButton = () => {
    if(!this.props.amendingEvent){return} 
    return (
      <button className={this.disableOrEnableButton()} onClick={(event) => this.continue(event, this.props.values)}>
        Update
      </button>)
  }

  getGoBackButton = () => {
    if(this.props.amendingEvent){return} 
    return (
      <button className="create-event-button" onClick={(event) => this.props.prevStep(event)}>
        Go Back
      </button>
      )
  }

  // getGoForwardButton = (values) => {
  //   if(this.props.amendingEvent || this.props.values.freeTicketsOnly){return} 

  //   return (
  //     <button className="create-event-button" onClick={(event) => this.continue(event, values)}>
  //       Continue
  //     </button>
  //     )
  // }


  continue = (event, values) => {
    event.preventDefault()
    if(this.props.freeTicketsOnly){
      //submit
      return
    }
    this.props.nextStep('purchaserDetails') 
  }

  getSubmitButton = (values) => {
    if(this.props.amendingEvent){return} 
    return (
      <button className="create-event-button green-button" onClick={(event) => this.props.submit(event)}>
        Submit Event
      </button>
      )
  }

  getButtons(values){
      return(  
        <>        
          {this.getUpdateButton()}
          {this.getGoBackButton()}
          {/* {this.getGoForwardButton(values)} */}
          {this.getSubmitButton(values)}

      </>)
  }

  displayTextInput = (values, i) => {
    if(!values.requiredPurchaserDetails[i].additionalDetail){return}
    return (
      <input 
        type='text' 
        placeholder='What information do you want?'
        value={values.requiredPurchaserDetails[i].valueName}
        onChange={(event) => this.props.nameRequiredPurchaserDetail(event, i)}
      />
    )
  }

  displayDetailTitle = (values, i) => {
    if(values.requiredPurchaserDetails[i].additionalDetail){return}
    return values.requiredPurchaserDetails[i].valueName
  }

  setNoneOptionToFalse = () => {
    this.setState({noneOption: false})
  }

  displayRequiredDetails = (values) => {
    return (
      <>
        {values.requiredPurchaserDetails.map((detail,i) => {
          
          return (
          <div key={i}>
            <input
              className="requiredPurchaseDetail" 
              type="checkbox"
              name={detail.valueName}
              checked={values.requiredPurchaserDetails[i].checked}
              onChange={() => {this.props.togglePurchaserDetailsCheckBox(i); this.setNoneOptionToFalse()}}
            />
            {' '}
            {this.displayTextInput(values, i)}
            {this.displayDetailTitle(values, i)}
          </div>
          )})
        }
      </>
    )

  }

  toggleNoneOption = () => {
    let noneOption = this.state.noneOption
    noneOption = !noneOption
    this.setState({noneOption})
    if(noneOption){this.props.unCheckAllPurchaserDetails()}
  }










  render() {
    const { values } = this.props;
    if(values.globalRefundOptions === undefined){return null}
    return (
      <>
        <form className="purchaser-details-form" >                           
          <div className="create-event-heading">
              <header>Request Purchaser Details</header>
              <hr />
          </div>  
          <div className="purchaser-details-checkbox-wrapper">
          <div>
            <input
              type="checkbox"
              name='none'
              checked={this.state.noneOption}
              onChange={() => this.toggleNoneOption(values)}
            />
            {' None'}
          </div>

          {this.displayRequiredDetails(values)}
        </div> 

        

        <button className="create-event-button" onClick={(event) => {this.props.addRequiredPurchaserDetail(event); this.setNoneOptionToFalse()}}>
            Add additional detail
        </button>

      
        <p className="create-event-warning">{this.state.message}</p>
        <div style={this.spinnerVisibility()}   className ='ticket-spinner'>
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
        <div id="message">{this.props.message}</div>
          <div className="create-event-button-container">
            {this.getButtons(values)}
          </div>
        </form>

      </>
      
    );
  }
}

export default PurchaserDetails;
