import React from "react";
import axios from "axios";
import "../Styles/Tickets.css";


class Unsubscribe extends React.Component {
    state = {
        message: 'Unsubscribing. Please Wait...'
      };
    
    componentDidMount() {
        let unsubscribeData = this.props.match.params.id.split(';')



        axios.post(`${process.env.REACT_APP_API}/unsubscribe`, {unsubscribeData}).then(res => {
        
            let message = res.data.message
            this.setState({message})
        })
    }


    render() {
      return (
        <>
            <div style={{margin: '20px'}}>{this.state.message}</div>
        </>
      )    
    }
  }

export default Unsubscribe;