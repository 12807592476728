import React, { Component } from 'react'
import axios from "axios";





export class Test extends Component {

  

 
    render() {
    
      
        return (
            <form className="create-event-form">

            </form> 
        )
    }
}

export default Test
