import React from "react";
import axios from "axios";
import moment from 'moment-timezone'
import "../Styles/Email.css";
import Footer from "./Footer";
import Nav from "./Nav";





class Email extends React.Component {
  state = {
    myEvents: [],
    marketEventMessage: '',
    marketEventMessageColor: '#293d6a',
    sendingEmails: false,
    marketEventSpinner: false,
    webLinkErrors: [],
    emailEventParticipants: '',
    emailParticipantsSubject: '',
    emailParticipantsContent: '',
    emailParticipantsMessageColor: '#293d6a',
    emailParticipantsMessage: '',
    emailParticipantsSpinner: false,
    emailSubjectError: false,
    emailContentError: false,
    eventReminder: '',
    eventReminderMessage: '',
    eventReminderMessageColor: '#293d6a',
    eventReminderSpinner: false,
    marketingEmailSubject: '',
    marketingEmailSubjectError: false
  };



  updateMarketingEmailSubject = (event) => {
    let marketingEmailSubject = event.target.value
    let marketingEmailSubjectError = false
    this.setState({marketingEmailSubject, marketingEmailSubjectError})
  }

  isValidHttpUrl = (string) => {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

 updateEmailParticipantsSubject = (event) => {

  let emailParticipantsSubject = event.target.value
  let emailSubjectError = false
  let emailParticipantsMessageColor = '#293d6a'
  let emailParticipantsMessage = ''
  this.setState({emailParticipantsSubject, emailSubjectError, emailParticipantsMessageColor, emailParticipantsMessage})
 }

 updateEmailParticipantsContent = (event) => {

  let emailParticipantsContent = event.target.value
  let emailContentError = false
  let emailParticipantsMessageColor = '#293d6a'
  let emailParticipantsMessage = ''
  this.setState({emailParticipantsContent, emailContentError, emailParticipantsMessageColor, emailParticipantsMessage})
}



  componentDidMount() {
    let token = localStorage.getItem("token");
    axios.post(`${process.env.REACT_APP_API}/profile`, {token: token})
      .then(res => {
        let myEvents = res.data.myEvents.map(e=>{
          e.selectedPrimaryEvent = false;
          e.selectedSecondaryEvent = false;
          e.webLink = ''
           return e})
        let webLinkErrors = [...Array(myEvents.length)].map(e => false)
       
        this.setState({ myEvents, webLinkErrors})
      })    
  }

  testWebLinksForErrors = () => {
    
    let primaryEventWebLinkError = this.state.myEvents.filter(e=> e.selectedPrimaryEvent).map(e => !this.isValidHttpUrl(e.webLink))
    let secondaryEventsWebLinkErrors = this.state.myEvents.map(e => !this.isValidHttpUrl(e.webLink))
    let webLinkErrors = [...primaryEventWebLinkError, ...secondaryEventsWebLinkErrors]
    let marketEventMessage = 'Please provide links to the webpage of each event'
    let marketEventMessageColor = 'red'
    this.setState({webLinkErrors, marketEventMessage, marketEventMessageColor})
    let secondaryEventsActualErrors = this.state.myEvents.filter(e=> e.selectedSecondaryEvent).map(e => !this.isValidHttpUrl(e.webLink))
    if(primaryEventWebLinkError.filter(e => e === true).length > 0 || secondaryEventsActualErrors.filter(e => e === true).length > 0){return true}
    return false
  }

  displayNoEventsMessage = () => {
    if(this.state.myEvents.length > 0){return}
    return (<div className="tickets-wrap center-text padding-left-10px padding-right-10px">You do not have any upcoming events</div>)
  }

  selectPrimaryEvent = (event) => {
    let selectedPrimaryEvent = event.target.value
    let myEvents = this.state.myEvents.map(e => {e.selectedPrimaryEvent = false; return e})
    let indexOfSelectedEvent = myEvents.findIndex(e => e._id === selectedPrimaryEvent)
    myEvents[indexOfSelectedEvent].selectedPrimaryEvent = true
    myEvents[indexOfSelectedEvent].selectedSecondaryEvent = false
    let marketEventMessageColor = '#293d6a'
    let marketEventMessage = ''
    this.setState({myEvents, marketEventMessage, marketEventMessageColor})
  }

  selectEmailEventParticipants = (event) => {
    let emailEventParticipants = event.target.value
    let emailParticipantsMessageColor = '#293d6a'
    let emailParticipantsMessage = ''
    this.setState({emailEventParticipants, emailParticipantsMessageColor, emailParticipantsMessage})
  }

  selectEventReminder = (event) => {
    let eventReminder = event.target.value
    let eventReminderMessageColor = '#293d6a'
    let eventReminderMessage = ''
    this.setState({eventReminder, eventReminderMessageColor, eventReminderMessage})
  }



  selectSecondaryEvent = (event) => {
    let myEvents = this.state.myEvents
    let eventSelected = event.target.value
    let indexOfThisEvent = myEvents.findIndex(e => e._id === eventSelected)
    myEvents[indexOfThisEvent].selectedSecondaryEvent = !myEvents[indexOfThisEvent].selectedSecondaryEvent
    this.setState({myEvents})
  }


  updateLink = (event, userEvent) => {
    console.log('update Link triggered')
    let myEvents = this.state.myEvents
    let indexOfThisEvent = myEvents.findIndex(e => e._id === userEvent._id)
    myEvents[indexOfThisEvent].webLink = event.target.value
    let marketEventMessageColor = '#293d6a'
    let marketEventMessage = ''
    let webLinkErrors = [...Array(myEvents.length)].map(e => false)
    this.setState({myEvents, marketEventMessageColor, marketEventMessage, webLinkErrors})

  }

  displayPrimaryEventWebLinkInput = (userEvent) => {
    let myEvents = this.state.myEvents
    let indexOfThisEvent = myEvents.findIndex(e => e._id === userEvent._id)
    if(!myEvents[indexOfThisEvent].selectedPrimaryEvent){return}
    return(  
      <div>   
      <input
          required
          className="email-web-link"
          type="url"
          placeholder="web link to event"
          value={userEvent.webLink}
          onChange={(event) => {this.updateLink(event, userEvent)}}
          style={{borderColor: this.state.webLinkErrors[0]?'red' : '#8f897c'}}
        />
        </div>
        )
  }

  displaySecondaryEventWebLinkInput = (userEvent) => {
    let myEvents = this.state.myEvents
    let indexOfThisEvent = myEvents.findIndex(e => e._id === userEvent._id)
    if(!myEvents[indexOfThisEvent].selectedSecondaryEvent){return}
    return(  
      <div>   
      <input
        required
        className="email-web-link email-invisible-border"
        type="url"
        placeholder="web link to event"
        value={userEvent.webLink}
        onChange={(event) => {this.updateLink(event, userEvent)}}
        style={{borderColor: this.state.webLinkErrors[indexOfThisEvent+1]?'red' : '#8f897c'}}
        />
        </div>
        )
  }

  noSecondaryEvents = () => {
    let myEvents = this.state.myEvents.map(e => {e.selectedSecondaryEvent=false;return e} )
    this.setState({myEvents})
  }

  getSecondaryEventList = () => {
    if(this.state.myEvents.filter(e => e.selectedPrimaryEvent).length === 0){return}
    if(this.state.myEvents.length ===1 ){return}
    let secondaryEvents = this.state.myEvents.filter(e => !e.selectedPrimaryEvent)


    return(
      <>
        <h5 className="email-select-heading">Select Additional Events to Mention at Bottom of Email</h5>
        <div className="text-align-left">
          <input
                  type="radio"
                  checked={this.state.myEvents.filter(e=>e.selectedSecondaryEvent).length === 0}
                  onChange={() => this.noSecondaryEvents()}
                  />
                {` None`}
        </div>
        {secondaryEvents.map((e, i) => {          
          
            return (
              <div key={i} className="text-align-left email-event-name">
                <div className="center-text font-size-10px"><i>{moment(e.startDetails).format('ddd Do MMM YY[,] HH:mm')}</i></div>
                <div>
                    <input
                    type="radio"
                    value={e._id}
                    checked={this.state.myEvents.find(f=>f._id===e._id).selectedSecondaryEvent}
                    onClick={event => this.selectSecondaryEvent(event)}
                    />
                  {` ${e.title}`}
                  {this.displaySecondaryEventWebLinkInput(e)}
                </div>
              </div>
              );
            })}
            <hr />
      </>
    )
  }

  sendEmailParticipantsEmails = async (event)=> {
    event.preventDefault()
    if(this.state.emailEventParticipants=== ''){
      let emailParticipantsMessage = 'Please select an event'
      let emailParticipantsMessageColor = 'red'
      this.setState({emailParticipantsMessage, emailParticipantsMessageColor})
      return
    }
    if(this.state.emailParticipantsSubject=== ''){
      let emailParticipantsMessage = `Please fill in the email's subject`
      let emailParticipantsMessageColor = 'red'
      let emailSubjectError = true
      this.setState({emailParticipantsMessage, emailParticipantsMessageColor, emailSubjectError})
      return
    }
    if(this.state.emailParticipantsContent=== ''){
      let emailParticipantsMessage = `Please fill in the email's content`
      let emailParticipantsMessageColor = 'red'
      let emailContentError = true
      this.setState({emailParticipantsMessage, emailParticipantsMessageColor, emailContentError})
      return
    }
    let emailParticipantsMessageColor = '#293d6a'
    let emailParticipantsMessage = 'Sending Emails. Please Wait...'
    let emailParticipantsSpinner = true
    let sendingEmails = true
    this.setState({emailParticipantsMessageColor, emailParticipantsMessage, emailParticipantsSpinner, sendingEmails})
    let emailSubject = this.state.emailParticipantsSubject
    let emailContent = this.state.emailParticipantsContent
    let token = localStorage.getItem("token");
    let userEventID = this.state.emailEventParticipants
    let res = await axios.post(`${process.env.REACT_APP_API}/emailEventParticipants`, {token, userEventID, emailSubject, emailContent})
    emailParticipantsSpinner = false
    sendingEmails = false
    emailParticipantsMessage = res.data.message
    if(res.data.success){emailParticipantsMessageColor = 'green'}
    if(!res.data.success){emailParticipantsMessageColor = 'red'}
    let emailEventParticipants = ''
    this.setState({emailEventParticipants, emailParticipantsMessage, emailParticipantsSpinner, sendingEmails, emailParticipantsMessageColor})
  }

  sendMarketingEmails = async (event) => {
    event.preventDefault()
    if(this.state.myEvents.filter(e => e.selectedPrimaryEvent).length === 0){
      let marketEventMessage = 'Please select an event'
      let marketEventMessageColor = 'red'
      this.setState({marketEventMessage, marketEventMessageColor})
      return
    }

    if(this.testWebLinksForErrors()){return}

    if(this.state.marketingEmailSubject === ''){
      let marketingEmailSubjectError = true
      let marketEventMessage = 'Please fill in the email subject'
      let marketEventMessageColor = 'red'
      this.setState({marketingEmailSubjectError, marketEventMessage, marketEventMessageColor})
      return
    }

    let marketEventMessageColor = '#293d6a'
    let marketEventMessage = 'Sending Emails. Please Wait...'
    let marketEventSpinner = true
    let sendingEmails = true
    this.setState({marketEventMessage, marketEventMessageColor, marketEventSpinner, sendingEmails})
    let token = localStorage.getItem("token");
    let myEvents = this.state.myEvents
    let res = await axios.post(`${process.env.REACT_APP_API}/marketingEmail`, {token, myEvents, subject:this.state.marketingEmailSubject})
    marketEventSpinner = false
    sendingEmails = false
    marketEventMessage = res.data.message
    if(res.data.success){marketEventMessageColor = 'green'}
    if(!res.data.success){marketEventMessageColor = 'red'}
    myEvents = myEvents.map(e => {e.selectedPrimaryEvent = false; e.selectedSecondaryEvent = false; return e})
    this.setState({marketEventMessage, marketEventSpinner, sendingEmails, myEvents, marketEventMessageColor})
  }

  
  sendEventReminderEmails = async (event) => {
    event.preventDefault()
    if(this.state.eventReminder=== ''){
      let eventReminderMessage = 'Please select an event'
      let eventReminderMessageColor = 'red'
      this.setState({eventReminderMessage, eventReminderMessageColor})
      return
    }

    let eventReminderMessageColor = '#293d6a'
    let eventReminderMessage = 'Sending Emails. Please Wait...'
    let eventReminderSpinner = true
    let sendingEmails = true
    this.setState({eventReminderMessageColor, eventReminderMessage, eventReminderSpinner, sendingEmails})
    let token = localStorage.getItem("token");
    let userEventID = this.state.eventReminder
    let res = await axios.post(`${process.env.REACT_APP_API}/eventReminderEmail`, {token, userEventID})
    console.log('res', res)
    eventReminderSpinner = false
    sendingEmails = false
    eventReminderMessage = res.data.message
    if(res.data.success){eventReminderMessageColor = 'green'}
    if(!res.data.success){eventReminderMessageColor = 'red'}
    let eventReminder = ''
    this.setState({eventReminderMessage, eventReminderSpinner, sendingEmails, eventReminder, eventReminderMessageColor})
  }

  displayEmailSubjectAndContent = () => {
    if(this.state.emailEventParticipants === ''){return}
    return (             
      <>
      <hr />
        <input
          required
          className="email-subject-input"
          type="text"
          placeholder="Email Subject"
          value={this.state.emailParticipantsSubject}
          onChange={(event) => {this.updateEmailParticipantsSubject(event)}}
          style={{borderColor: this.state.emailSubjectError ?'red' : '#8f897c'}}
        />

        <textarea
          required
          className="email-content-input"
          type="text"
          placeholder="Email Content"
          value={this.state.emailParticipantsContent}
          onChange={(event) => {this.updateEmailParticipantsContent(event)}}
          style={{borderColor: this.state.emailContentError ?'red' : '#8f897c'}}
        />

      </>
    )
  }


  render() {
    return (
      <>
        <Nav />
        <div className="content-wrapper">
        <div className="my-events-heading">
              <header>Email Customers</header>
              <hr />
          </div>  

          <form className="my-events-heading">
            <header>Market Event by Email</header>
            <hr />
            <h5 className="email-select-heading">Select Main Event to Email</h5>
            <div>
              {this.state.myEvents.filter(e => moment(e.endDetails).isAfter(moment())).map((e, i) => {
              return (
                <div key={i} className="text-align-left email-event-name">
                  <div className="center-text font-size-10px"><i>{moment.tz(e.startDetails,"Europe/Dublin").format('ddd Do MMM YY[,] HH:mm')}</i></div>
                  <label>
                      <input
                        type="radio"          
                        checked={e.selectedPrimaryEvent}
                        value={e._id}
                        onClick={event => this.selectPrimaryEvent(event)}
                        name="primaryEvent"
                      />
                    {` ${e.title}`}
                    {this.displayPrimaryEventWebLinkInput(e)}
                  </label>
                </div>
                
                );
              })}
            </div>
            <hr />
            {this.getSecondaryEventList()}
            <input
              required
              className="email-subject-input marketing-email-subject"
              type="text"
              placeholder="Email Subject"
              value={this.state.marketingEmailSubject}
              onChange={(event) => {this.updateMarketingEmailSubject(event)}}
              style={{borderColor: this.state.marketingEmailSubjectError ?'red' : '#8f897c'}}
             />
            <div className ={this.state.marketEventSpinner ? 'lds-default': 'display-none'}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <div style={{color: this.state.marketEventMessageColor}}>{this.state.marketEventMessage}</div>
            <button id={this.state.sendingEmails ?  'disable-purchase-button': 'purchase-button'} className="send-emails-button"  onClick={(event) => {this.sendMarketingEmails(event)}}>Send Emails</button>
          </form>  

          <form className="my-events-heading">
            <header>Email Event Customers</header>
            <hr />
            <h5 className="email-select-heading">Select Event</h5>
            <div>
              {this.state.myEvents.filter(e => moment(e.endDetails).isAfter(moment())).map((e, i) => {
              return (
                <div key={i} className="text-align-left email-event-name">
                  <div className="center-text font-size-10px"><i>{moment.tz(e.startDetails,"Europe/Dublin").format('ddd Do MMM YY[,] HH:mm')}</i></div>
                  <label>
                      <input
                        type="radio"          
                        checked={e._id === this.state.emailEventParticipants}
                        value={e._id}
                        onChange={event => this.selectEmailEventParticipants(event)}
                      />
                    {` ${e.title}`}
                  </label>
                </div>
                
                );
              })}
            </div>
              {this.displayEmailSubjectAndContent()}
            <hr />
            <div className ={this.state.emailParticipantsSpinner ? 'lds-default': 'display-none'}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <div style={{color: this.state.emailParticipantsMessageColor}}>{this.state.emailParticipantsMessage}</div>
            <button id={this.state.sendingEmails ?  'disable-purchase-button': 'purchase-button'} className="send-emails-button"  onClick={(event) => {this.sendEmailParticipantsEmails(event)}}>Send Emails</button>
          </form>  

          <form className="my-events-heading">
            <header>Send Event Reminder</header>
            <hr />
            <h5 className="email-select-heading">Select Event</h5>
            <div>
              {this.state.myEvents.filter(e => moment(e.endDetails).isAfter(moment())).map((e, i) => {
              return (
                <div key={i} className="text-align-left email-event-name">
                  <div className="center-text font-size-10px"><i>{moment.tz(e.startDetails,"Europe/Dublin").format('ddd Do MMM YY[,] HH:mm')}</i></div>
                  <label>
                      <input
                        type="radio"          
                        checked={e._id === this.state.eventReminder}
                        value={e._id}
                        onChange={event => this.selectEventReminder(event)}
                      />
                    {` ${e.title}`}
                  </label>
                </div>
                
                );
              })}
            </div>
            <hr />
            <div className ={this.state.eventReminderSpinner ? 'lds-default': 'display-none'}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <div style={{color: this.state.eventReminderMessageColor}}>{this.state.eventReminderMessage}</div>
            <button id={this.state.sendingEmails ?  'disable-purchase-button': 'purchase-button'} className="send-emails-button"  onClick={(event) => {this.sendEventReminderEmails(event)}}>Send Emails</button>
          </form>  
          

        </div>
        <Footer />
        </>
      
    )    
  }
}
export default Email;