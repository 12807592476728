import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Nav from "./Nav";
import Footer from "./Footer";
import EventCard from "./EventCard";
import FilterOptions from "./FilterOptions";

class Events extends React.Component {
  state = {
    events: [],
    filters:{
      gayMen:true,
      lesbian:true,
      next7Days:true,
    },
    pageLoading:true

  };

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_API}/events`)
      // .get(`https://v4fyl0btyc.execute-api.eu-west-1.amazonaws.com/prod/getEvents`)

      
      .then((res) => {
        this.setState({
          events: res.data,
          pageLoading:false
        });
      })
      .catch((err) => {console.log('err', err)});
  }

  toggleFilters = (filter) =>{
    let filters = this.state.filters
    filters[filter] = !filters[filter]
    this.setState({filters})
  }

  filterEvents = () => {
    let events = this.state.events;
    let filters = this.state.filters;
  
    let everyBody = (!filters.gayMen && !filters.lesbian) ? [] : events.filter(e => e.everybody);
    let gayMen = filters.gayMen ? events.filter(e => e.gayMen) : [];
    let lesbians = filters.lesbian ? events.filter(e => e.lesbians) : [];
  
    let filteredEvents = [...everyBody, ...gayMen, ...lesbians];
  
    if (filters.next7Days) {
      let now = new Date();
      let sevenDaysLater = new Date();
      sevenDaysLater.setDate(now.getDate() + 7);
      filteredEvents = filteredEvents.filter(e => this.isWithinNextSevenDays(e.startDetails, now, sevenDaysLater));
    }
  
    return filteredEvents.sort((a, b) => new Date(a.startDetails) - new Date(b.startDetails));
  }

  isWithinNextSevenDays = (date, now, sevenDaysLater) => {
    let eventDate = new Date(date);
    return eventDate >= now && eventDate <= sevenDaysLater;
  }

  handleShowMoreEvents = () => {
    let filters = this.state.filters
    filters.next7Days = false
    this.setState({filters})
  }

  displayShowMoreEventsButton = () => {
    if (!this.state.filters.next7Days) {return}
    return (
      <div className="events-button-container">
        <button className="events-show-more-events-btn" onClick={this.handleShowMoreEvents}>
          Show more events
        </button>
        <div>{this.state.pageLoading}</div>
      </div>
    )
  }

  render() {
    let events = this.filterEvents()
    console.log('this.state.pageLoading', this.state.pageLoading)
    return (
      <>
        <Nav currentPage="events" filters={this.state.filters} toggleFilters={this.toggleFilters}/> 
        <div className={this.state.pageLoading ? "event-page-loading" : "display-none"}>
          <div className ={'lds-default'}>
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
          </div>
        </div>
        

        <div className={this.state.pageLoading ? "display-none" : "filter-hide-on-mobile"}>
          <FilterOptions filters={this.state.filters} toggleFilters={this.toggleFilters}/>
        </div> 

        <div className={this.state.pageLoading ? "display-none" : "content-wrapper events-content-wrapper"}>

            {events.map((e, i) => {
              return (
                <Link 
                  to={`/events/${e._id}`} 
                  key={i} 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <EventCard
                    name={e.title}
                    organiser={e.organiserName}
                    location={`${e.venue}, ${e.address1}`}
                    startDetails={e.startDetails}
                    price={e.price}
                    image={e.imageURL}
                    index={i}
                  />
                </Link>
              );
            })}

            {this.displayShowMoreEventsButton()}
          </div>
          <Footer />
      </>
    );
  }
}

export default Events;
