import React, {useState, useEffect} from "react";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import FilterOptions from "./FilterOptions";
import "../Styles/Nav.css";




class Nav extends React.Component { 

  

  state = {
    displayMobileNav: false,
    displayDropDown: false
  }



  tokenCheck = () => {
    let token = localStorage.getItem("token");
    if (token) {
      return true;
    } else {
      return false;
    }
  };

  logout = () => {
    localStorage.removeItem("token");
    this.props.history.push({
      pathname: "/events",
    });
  };

  toggleMobileNav = () => {
    let displayMobileNav = !this.state.displayMobileNav
    this.setState({displayMobileNav})
  };

  displayMobileClass = () => {
    if(this.state.displayMobileNav){return `nav-mobile nav-display-mobile-menu`}
    return `nav-mobile`
  }

  loggedInMobileLinks = () => {
    return(
      <ul onClick={() => this.toggleMobileNav()} >              
        <li className="nav-link-wrapper">
          <NavLink
            exact
            activeClassName="nav-current-page"
            className="nav-btn"
            to={`/events`}
          >
            Home
          </NavLink>
        </li>
        <li className="nav-link-wrapper">
          <NavLink
            exact
            activeClassName="nav-current-page"
            className="nav-btn"
            to={`/contactUs`}
          >
            Contact Us
          </NavLink>
        </li>
        <li className="nav-link-wrapper">
          <NavLink
            exact
            activeClassName="nav-current-page"
            className="nav-btn"
            to={`/createevent`}
          >
            Create Event
          </NavLink>
        </li>

        <li className="nav-link-wrapper">
          <NavLink
            className="nav-btn"
            to={`/tickets`}
          >
            My Tickets
          </NavLink>
        </li>
        <li className="nav-link-wrapper">
          <NavLink
            to={`/waitLists`}
            className="nav-btn"
          >
            My WaitLists
          </NavLink>
        </li>
        <li className="nav-link-wrapper">
          <NavLink
            to={`/myevents`}
            className="nav-btn"
          >
            My Events
          </NavLink>
        </li>
        {/* <li className="nav-link-wrapper">
          <NavLink
            to={`/email`}
            className="nav-btn"
          >
            Email Customers
          </NavLink>
        </li> */}
        <li className="nav-link-wrapper">
          <div className="nav-btn" onClick={() => this.logout()}>
            Log Out
          </div>
        </li>
      </ul>
    )

  }

  closeMobileNav = () => {
    let displayMobileNav = false
    this.setState({displayMobileNav})
  }

  displayNavBars = () => {
    return (
    <div className="nav-icon">
      <FontAwesomeIcon 
        icon={this.state.displayMobileNav ?   faX : faBars} 
        onClick={() => this.toggleMobileNav()} 
        className="nav-bars"
      />
    </div>
    )
  }

  toggleDropDown = () => {
    console.log('toggleDropDown')
    let displayDropDown = !this.state.displayDropDown
    this.setState({displayDropDown})
  }

  
         






  displayLoggedInPCLinks = () => {
    if(!this.tokenCheck()){return}
    return (
      <ul className="nav-pc">

        <div className="nav-spacing nav-hide-on-mobile">
          
          <li className="nav-link-wrapper">
            <NavLink
              exact
              activeClassName="nav-current-page"
              className="nav-btn"
              to={`/events`}
            >
              Home
            </NavLink>
            </li>
        </div>

        <div className="nav-spacing nav-hide-on-mobile">
          <li className="nav-link-wrapper">
            <NavLink
              exact
              activeClassName="nav-current-page"
              className="nav-btn"
              to={`/contactUs`}
            >
              Contact Us
            </NavLink>
            </li>
          </div>

        <div className="nav-spacing nav-hide-on-mobile">
          <li className="nav-link-wrapper">
            <NavLink
              exact
              activeClassName="nav-current-page"
              className="nav-btn"
              to={`/createevent`}
            >
              Create Event
            </NavLink>
          </li>
        </div>



        <div className="nav-spacing nav-hide-on-mobile">
          <li>
            <div className="nav-account" 
              onMouseOver={() => this.toggleDropDown()}
              onMouseOut={() => this.toggleDropDown()}
            >
              My Account
              <ul className="nav-dropdown" 
                style={this.state.displayDropDown ? {display: 'block'} : {display: 'none'}}>
                <li className="nav-sub-link nav-link-wrapper">
                  <NavLink
                    className="nav-btn"
                    to={`/tickets`}
                  >
                    My Tickets
                  </NavLink>
                </li>
                <li className="nav-sub-link nav-link-wrapper">
                  <NavLink
                    to={`/waitLists`}
                    className="nav-btn"
                  >
                    My WaitLists
                  </NavLink>
                </li>
              
                <li className="nav-sub-link nav-link-wrapper">
                  <NavLink
                    to={`/myevents`}
                    className="nav-btn"
                  >
                    My Events
                  </NavLink>
                </li>

              </ul>
            </div>
          </li>
        </div>
        
        <div className="nav-spacing nav-hide-on-mobile">
          <li className="nav-link-wrapper">
            <div className="nav-btn" onClick={() => this.logout()}>
              Log Out
            </div>
          </li>
        </div>
        </ul>
    )
  }





  displayLoggedOutPCLinks = () => {
    if(this.tokenCheck()){return}
    return (
      <ul className="nav-pc">

        <div className="nav-spacing">
          
          <li className="nav-link-wrapper">
            <NavLink
              exact
              activeClassName="nav-current-page"
              className="nav-btn"
              to={`/events`}
            >
              Home
            </NavLink>
            </li>
        </div>

        <div className={"nav-spacing"}>
          <li className="nav-link-wrapper">
            <NavLink
              exact
              activeClassName="nav-current-page"
              className="nav-btn"
              to={`/contactUs`}
            >
              Contact Us
            </NavLink>
            </li>
          </div>
          <div className="nav-spacing">
            <li className="nav-link-wrapper">
              <NavLink
                exact
                activeClassName="nav-current-page"
                className="nav-btn"
                to={`/signup`}
              >
                Sign Up
              </NavLink>
            </li>
          </div>

          <div className="nav-spacing">
            <li className="nav-link-wrapper">
              <NavLink
                exact
                activeClassName="nav-current-page"
                className="nav-btn"
                to={`/login`}
              >
                Log In
              </NavLink>
            </li>
          </div>
        </ul>
    )
  }

  displayFilterOptionsInNav = () => {
    if(this.props.currentPage!=="events"){return}
    return (
      <div className="filter-hide-on-non-mobile">
        <FilterOptions nav={true} filters={this.props.filters} toggleFilters={this.props.toggleFilters}/>
      </div> 
    )
  }

  render() {

      return (
        <nav>
          <div className={this.displayMobileClass()}>{this.loggedInMobileLinks()}</div>
          {this.displayNavBars()}
          {this.displayFilterOptionsInNav()}
          {this.displayLoggedInPCLinks()}
          {this.displayLoggedOutPCLinks()}

        </nav>
      );
    


  }
}

export default withRouter(Nav);
