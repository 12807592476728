import React from "react";
import QrReader from "react-qr-reader";
import axios from "axios";
import moment from "moment";
import "../Styles/CheckIn.css";
import Footer from "./Footer";
import PurchasedTicket from "./PurchasedTicket";
import Nav from "./Nav";



class CheckIn extends React.Component {

  state = {
    eventRequested: '',
    password: '',
    message: '',
    userEvent: {},
    showCheckIn: false,
    scanTicket: false,
    displaySpinner: false,
    messageColor: 'black',
    image: '',
    displayImage: false,
    search: '',
    tickets: [],
    manualCheckIn: false
  }

  componentDidMount() {    
    let eventRequested = this.props.match.params.id 
    this.setState({eventRequested})

  }

  updateTicket = (updatedTicket, index) => {
    console.log('updatedTicket', updatedTicket, 'index', index)

    let tickets = this.state.tickets
    console.log('tickets', tickets)
    console.log('tickets[index]', tickets[index])
    tickets[index] = updatedTicket
    this.setState({tickets})
  }

  toggleManualCheckIn(){
    let manualCheckIn=!this.state.manualCheckIn
    let displayImage = false
    let message = ''
    let scanTicket = false
    this.setState({manualCheckIn, displayImage, message, scanTicket})
  }

  checkInLogIn = (e) => {
      e.preventDefault()
      let message = `Checking Details. Please Wait...`
      let displaySpinner = true
      this.setState({message, displaySpinner})

      axios.post(`${process.env.REACT_APP_API}/checkInLogIn`, {userEvent: this.state.eventRequested, password: this.state.password}).then(res => {
        displaySpinner = false   
        this.setState({message: res.data.message, userEvent: res.data.userEvent, showCheckIn: res.data.success, displaySpinner: false})
    
    }).catch(err => console.log(err));

  }

  eventDetails = (e, field) => {
      let stateCopy = this.state
      stateCopy[field]=e.target.value
      this.setState(stateCopy)
  }

  handleScan = (data, userEvent) => {
    if (data) {

      this.turnScannerOnOff(data, true)
      this.setState({message: "QR code scanned. Checking database for match...", displayImage: false})

      axios.post(`${process.env.REACT_APP_API}/checkIn`, {qrcode: data, userEvent: userEvent, password: this.state.password}).then(res => {
        let message = res.data.message
        let messageColor = this.state.color
        let image = this.state.image
        let displayImage = true
        if(res.data.checkedIn === true){
          messageColor = 'green'
          image = `${process.env.REACT_APP_API}/greenTick.png`
        }else{
          messageColor = 'red'
          image = `${process.env.REACT_APP_API}/redx.png`
        }
        if(res.data.tryAgain){
          messageColor = '#d8420b'
          image = `${process.env.REACT_APP_API}/caution.png`
        }
        this.setState({message, messageColor, image, displayImage})
        document.getElementsByClassName('check-in-image')[0].scrollIntoView({behavior: "smooth"})
        if(res.data.checkedIn && this.state.tickets.length > 0){
          this.searchTickets(false)
        }
        }).catch(err => {console.log(err)})
    }
  }

  logOut = (event) => {
    event.preventDefault()
    this.setState({
      message: '',
      showCheckIn: false,
      checkIn: false,
      password: '',
      userEvent: {},
      image: ''
    })

  }

  turnScannerOnOff = (event, scanInProgress = false) => {
    if(!scanInProgress){event.preventDefault()}
    let displayImage = false
    let message = ''
    let scanTicket = !this.state.scanTicket
    let manualCheckIn = false
    this.setState({displayImage, message, scanTicket, manualCheckIn})
  }

  spinnerVisibility(){
    if(this.state.displaySpinner ){return {'display': 'block'}}
    return {'display': 'none'}
  }

  displayImage(){
    if(this.state.displayImage ){return {'display': 'block'}}
    return {'display': 'none'}
  }

  displayLogInForm(){
    return(
      <div className="check-in-container">
        <form className="check-in-form">
          <div className="check-in-heading">
            <header>Check In</header>
            <hr />
          </div>
          <div>
              <input
                  value={this.state.eventRequested}
                  required
                  onChange={event => this.eventDetails(event, 'eventRequested')}
                  type='text'
                  placeholder='Event ID'
              />
          </div>
          <div>
              <input
                  value={this.state.password}
                  required
                  onChange={event => this.eventDetails(event, 'password')}
                  type='password'
                  placeholder='Password'
              />
          </div>
        <div className="check-in-spinner-message">
          <div style={this.spinnerVisibility()} className ='ticket-spinner'>
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div> 
          </div>
          <div className="check-in-message">{this.state.message}</div>
          <div>{this.state.image}</div>
        </div>
        <div className="check-in-button-container">
          <button onClick={this.checkInLogIn}>Submit</button>
        </div>
      </form>
    </div>
    )
  }

  displayQRReader = () => {
    console.log('displayQR REader')
    if(!this.state.scanTicket){return}
    return (
            <QrReader
              delay={300}
              onError={this.handleError}
              onScan={event => this.handleScan(event, this.state.userEvent._id)}
              style={{ width: '100vw', maxWidth: '600px', margin: '10px auto'}}
            />)
  }





  updateSearch(event){
    event.preventDefault()
    let search = event.target.value
    this.setState({search})

  }

  searchTickets(updateMessage=true){
    if(this.state.search === ''){return}
    let message = `Searching Tickets. Please Wait...`
    let displaySpinner = true
    this.setState({message, displaySpinner})
    axios.post(`${process.env.REACT_APP_API}/searchTickets`, {email: this.state.search, userEvent: this.state.eventRequested, 
      password: this.state.password})
    .then(res => {
      let tickets = []
      this.setState({tickets})
      displaySpinner = false   
      this.setState({tickets: res.data.tickets})
      if(updateMessage){this.setState({message: res.data.message,})}
    }).catch(err => console.log(err));
  }

  displayManualCheckIn(){
    if(!this.state.manualCheckIn){return}
    return(
      <>
        <hr />
        <div className="check-in-manual-wrapper">
          <input type="email" placeholder="Enter email address" id="my-event-refund-search-emails" value={this.state.search} onChange={(event) => this.updateSearch(event)} />
          <div><button className='create-event-button my-event-search-refunds-button' onClick={(event) => this.searchTickets(event)}>Search</button></div>
        </div>
        <hr />
      </>
    )
  }

  displaySearchResults(){
    if(!this.state.manualCheckIn){return}
    let tickets = this.state.tickets
    if(tickets.length === 0){return}
    return (<>
    
    {tickets.map((ticket, index)=> {
      ticket.userEvent = this.state.userEvent
      return( <div key={ticket._id}>
          <PurchasedTicket
            ticket = {JSON.parse(JSON.stringify(ticket))}
            requestFromOrganiser = {true}
            checkedInTicketsOnly = {true}
            checkInRequest = {true}
            password={this.state.password}
            updateTicket={this.updateTicket}
            index = {index}
          />
        </div>)}
        )}</>)
  }


  displayCheckInForm(){
    return(
      
      <div className='check-in-container'>
      <div className='check-in-scan'>
        
        <div className="check-in-event-details">
          <header>{this.state.userEvent.title}</header>
          <div className="check-in-date">{moment(this.state.userEvent.startDetails).format("Do MMM YYYY [at] HH:mm ")}</div>
          <div>{this.displayManualCheckIn()}</div>
          <div>{this.displayQRReader()}</div>
          <div style={this.state.scanTicket?{display:'block'}:{display:'none'}}>
          </div>
          <img className="check-in-image" src={this.state.image} alt={'log in response'} style={this.displayImage()}  className='check-in-image'/>
          <p  style={{color: this.state.messageColor}}>{this.state.message}</p>
          {this.displaySearchResults()}
        </div>


        <div className="check-in-button-wrapper">
          <button onClick={(event) => this.turnScannerOnOff(event)}> {this.getButtonText()}</button>
          <button onClick={(event) => this.toggleManualCheckIn(event)}> Manual Check In</button>
          <button onClick={event => this.logOut(event)}>Log Out of Event</button>
        </div>
      </div>
      </div>
      
    )
  }

  getButtonText(){
    if(this.state.checkIn){return 'Turn Scanner Off'}
    return 'Scan Ticket'
  }


  render() {
    return (
<>
  
    <Nav />

    {this.state.showCheckIn ? this.displayCheckInForm()  :  this.displayLogInForm()}
    <Footer />
 
</>
		    )
		  }
		}

export default CheckIn;
